import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getDiscountDescription, getItemDescription } from '@Utils/pos-utils';
import LargeNumpadModal from '@Components/inputs/large-numpad-modal';

class EditSaleItemModal extends Component {
  onSave = ({ discountType, discountValue, quantity, amount, comment, cashierId }) => {
    const { item } = this.props;
    const baseItem = (item || {});
    const isVoucherSale = baseItem.itemType === 'VoucherSale';

    const description = baseItem.itemType === 'Discount'
      ? getDiscountDescription(discountValue, discountType)
      : baseItem.description;

    return this.props.onSave({
      ...baseItem,
      showEditModal: false,
      discountType,
      discountValue,
      quantity,
      amount,
      description,
      comment: isVoucherSale ? baseItem.comment : comment,
      voucherBeneficiary: isVoucherSale ? comment : null,
      sellerId: cashierId || null
    });
  };

  render() {
    const { item, onClose, grossAmount, cashiersById } = this.props;
    const { description, itemType, comment } = item || {};
    const title = item ? getItemDescription(description, itemType) : 'Rabatt';
    const isNotDiscount = item && itemType !== 'Discount';
    const isVoucherSale = itemType === 'VoucherSale';
    const selectPrice = item && item.showEditModal && item.amount === 0;
    const itemComment = isVoucherSale ? item.voucherBeneficiary : comment;

    return (
      <LargeNumpadModal
        onSave={this.onSave}
        onClose={onClose}
        title={title}
        item={{ ...item, comment: itemComment }}
        selectedType={selectPrice ? 'amount' : null}
        grossAmount={grossAmount}
        showQuantity={isNotDiscount && !isVoucherSale}
        showAmount={isNotDiscount && !isVoucherSale}
        showCashier={isNotDiscount}
        commentPlaceholder={isVoucherSale ? 'Förmånstagare' : null}
        cashiersById={cashiersById}
        showComment
        showDiscount
      />
    );
  }
}

const mapStateToProps = state => ({
  cashiersById: state.cashiersById
});

export default connect(mapStateToProps)(EditSaleItemModal);
