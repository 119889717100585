import { defineMessages } from 'react-intl';

export default defineMessages({
  btnLabel: {
    id: 'calendar.calendarViewSettings.btnLabel',
    defaultMessage: 'Settings'
  },
  highContrast: {
    id: 'calendar.calendarViewSettings.highContrast',
    defaultMessage: 'Increased contrast'
  },
  gridSize: {
    id: 'calendar.calendarViewSettings.gridSize',
    defaultMessage: 'Increased calendar size'
  },
  datePickerPosition: {
    id: 'calendar.calendarViewSettings.datePickerPosition',
    defaultMessage: 'Place date picker top'
  },
  showHolidays: {
    id: 'calendar.calendarViewSettings.showHolidays',
    defaultMessage: 'Show public holidays in the schedule'
  },
  associatedResource: {
    id: 'calendar.calendarViewSettings.associatedResource',
    defaultMessage: 'Show associated resource on booking'
  },
  showNotesIndicator: {
    id: 'calendar.calendarViewSettings.showNotesIndicator',
    defaultMessage: 'Show notes indicator'
  },
  externalKeyboard: {
    id: 'calendar.calendarViewSettings.externalKeyboard',
    defaultMessage: 'External keyboard'
  },
  webBookingIndicator: {
    id: 'calendar.calendarViewSettings.webBookingIndicator',
    defaultMessage: 'View web booking status'
  },
  darkenClosedDays: {
    id: 'calendar.calendarViewSettings.darkenClosedDays',
    defaultMessage: 'Mark non bookable days in gray'
  },
  calendarInterval: {
    id: 'calendar.calendarViewSettings.calendarInterval',
    defaultMessage: 'Calendar interval'
  },
  confirmMoveBooking: {
    id: 'calendar.calendarViewSettings.confirmMoveBooking',
    defaultMessage: 'Confirm move of booking'
  },
  minutes: {
    id: 'calendar.calendarViewSettings.minutes',
    defaultMessage: '{minutes} minutes'
  },
  alwaysShowTime: {
    id: 'calendar.calendarViewSettings.alwaysShowTime',
    defaultMessage: 'Always show time on booking'
  },
  alwaysShowServiceOnNewLine: {
    id: 'calendar.calendarViewSettings.alwaysShowServiceOnNewLine',
    defaultMessage: 'Always show service on new line'
  },
  webBookingColor: {
    id: 'calendar.calendarViewSettings.webBookingColor',
    defaultMessage: 'Show web booking in other color'
  },
  paidBookingBadge: {
    id: 'calendar.calendarViewSettings.paidBookingBadge',
    defaultMessage: 'Show paid bookings with badge'
  },
  showChipTooltip: {
    id: 'calendar.calendarViewSettings.showChipTooltip',
    defaultMessage: 'Show tooltip on booking hover'
  }
});
