import moment from 'moment';
import { calcWeekViewTimeOffsetForDay } from '@Utils/time-util';

export function calculateScrollbarWidth() {
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.width = '100px';
  outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps

  document.body.appendChild(outer);

  const widthNoScroll = outer.offsetWidth;

  // force scrollbars
  //
  outer.style.overflow = 'scroll';

  // add inner div
  //
  const inner = document.createElement('div');
  inner.style.width = '100%';
  outer.appendChild(inner);

  const widthWithScroll = inner.offsetWidth;

  // remove divs
  outer.parentNode.removeChild(outer);

  return widthNoScroll - widthWithScroll;
}

export function blockClosed(block) {
  if (block) {
    const {
      webOpen, vipOpen, closedMax, day, closed
    } = block;
    const dayPast = moment(day).isBefore(moment(), 'day');
    return closed || closedMax || dayPast || (!webOpen && !vipOpen);
  }
}

class ChipCluster {
  constructor(idx, bk, groupLen, colWidth, indentPixels) {
    this.idx = idx;
    this.clstStartMs = bk.startTime.valueOf();
    this.clstEndMs = this.clstStartMs + (groupLen * 60 * 1000);
    this.count = 0;
    this.chips = [];
    this.colWidth = colWidth;
    this.indentPixels = indentPixels;
    this.clstMaxEndMs = this.clstEndMs;
    this.indent = 0;
    this.addIfBelongsToGroup(bk);
  }

  overlaps(cluster) {
    return this.clstStartMs < cluster.clstMaxEndMs && this.clstStartMs >= cluster.clstStartMs;
  }

  increaseIndentLevel() {
    this.indent++;
  }

  get indentLevel() {
    return this.indent;
  }

  get clusterStart() {
    return this.clstStartMs;
  }

  get clusterId() {
    return this.idx;
  }

  addIfBelongsToGroup(chip) {
    const bkStartMs = chip.startTime.valueOf();
    const bkEndMs = chip.endTime.valueOf();

    if (bkStartMs <= this.clstEndMs && bkStartMs >= this.clstStartMs) {
      const newChip = { ...chip };
      this.count++;
      this.clstMaxEndMs = bkEndMs > this.clstMaxEndMs ? bkEndMs : this.clstMaxEndMs;
      this.chips.push(newChip);
      return true;
    }
    return false;
  }

  getChips() {
    return this.chips;
  }
}

export function createPositionedChips(chips, columnIndex, gridProps, viewMode) {
  const chipClusters = createChipClusters(chips, gridProps);
  return makePositionedChips(chipClusters, columnIndex, gridProps, viewMode);
}

function createChipClusters(chips, gridProps) {
  const { columnWidth, chipIndentPixels, gridScrollHeight } = gridProps;

  const clusters = [];
  const maxClusterLengthMinutes = 24;
  let currentCluster = null;
  let clusterIdx = -1;

  // Sort by time - otherwise the cluster calculation will have funny results
  //
  chips = chips.sort((chip1, chip2) => {
    if (chip1.startTime < chip2.startTime) return -1;
    if (chip1.startTime > chip2.startTime) return 1;
    return 0;
  });

  _.each(chips, (chip) => {
    if (chip.cancelled === true) {
      clusters.push(new ChipCluster(++clusterIdx,
        chip,
        1,
        columnWidth,
        chipIndentPixels,
        gridScrollHeight));
      return;
    }

    // Create a separate cluster for the DRAGGER chip - this is avoid that the copy&paste dragger interferes with the clusters..

    if (chip.id === 'DRAGGER') {
      clusters.push(new ChipCluster(++clusterIdx,
        chip,
        1,
        columnWidth,
        chipIndentPixels,
        gridScrollHeight));
      return;
    }

    /* eslint-disable-next-line no-empty */
    if (currentCluster !== null && currentCluster.addIfBelongsToGroup(chip)) {
      // Vetefan hur jag ska kunna skriva den här koden utan en tom if sats
    } else {
      // Make cluster length 1 min shorter than first chip (max 24 min)
      //
      let clusterLengthMinutes = (chip.endTime - chip.startTime) / (1000 * 60) - 1;
      if (clusterLengthMinutes > maxClusterLengthMinutes) {
        clusterLengthMinutes = maxClusterLengthMinutes;
      }

      currentCluster = new ChipCluster(++clusterIdx,
        chip,
        clusterLengthMinutes,
        columnWidth,
        chipIndentPixels,
        gridScrollHeight);
      clusters.push(currentCluster);
    }
  });
  return clusters;
}

function makePositionedChips(clusters, columnIndex, gridProps, viewMode) {
  const {
    columnWidth, gridScrollHeight, gridClientWidth, chipIndentPixels
  } = gridProps;

  const colLPosOffset = columnWidth * columnIndex;
  const chips = [];
  const minToPixelFactor = (gridScrollHeight / 24) / 60;
  const isWeekView = viewMode === 'week';

  // Set indent for overlapping clusters
  //
  for (const outerCluster of clusters) {
    for (const innerCluster of clusters) {
      if (outerCluster.overlaps(innerCluster)) {
        outerCluster.increaseIndentLevel();
      }
    }
  }

  for (const cluster of clusters) {
    const clusterChips = cluster.getChips();
    const clusterChipWidth = parseInt((columnWidth - cluster.indentLevel * 2) / cluster.count);
    const clusterChipWidthPct = (clusterChipWidth - cluster.indentLevel * 2) / gridClientWidth * 100;
    const clusterChipWidthCancelledPct = clusterChipWidth / gridClientWidth * 50;
    const leftPosDragPct = colLPosOffset / gridClientWidth * 100;

    // Make a copy, momentjs startOf() metod mutates the original object
    //
    const dayStart = moment(cluster.clusterStart).startOf('day').valueOf();
    const compTime = calcWeekViewTimeOffsetForDay(dayStart) * 1000;

    let chipIndex = 0;

    for (const chip of clusterChips) {
      const chipStartMs = chip.startTime.valueOf();
      const chipEndMs = chip.endTime.valueOf();

      const indentor = cluster.indentLevel >= 2 ? cluster.indentLevel * chipIndentPixels + 1 : chipIndentPixels;

      const lpos = chipIndex++ * clusterChipWidth + (chip.cancelled ? 0 : indentor);

      const leftPosPx = lpos + colLPosOffset;
      const leftPosPct = leftPosPx / gridClientWidth * 100;
      const top = (chipStartMs - dayStart + compTime) / (1000 * 60) * minToPixelFactor + (chipIndentPixels / 2);
      const height = (chipEndMs - chipStartMs) / (1000 * 60) * minToPixelFactor - chipIndentPixels;
      const zIndex = parseInt(100 + (top / gridScrollHeight * 100));

      const initialCoords = {
        top,
        height,
        zIndex: (chip.cancelled ? 100 : zIndex),
        leftPct: leftPosPct,
        widthPct: (chip.cancelled ? clusterChipWidthCancelledPct : clusterChipWidthPct),
        width: (chip.cancelled ? clusterChipWidth / 2 : clusterChipWidth),
        colIdx: columnIndex,
        leftPosDragPct
      };

      const newChip = {
        ...chip,
        initialCoords,
        chipId: chip.id,
        clusterId: cluster.clusterId
      };

      chips.push(newChip);
    }
  }
  return chips;
}

export function getScheduleBlockForColumn(scheduleBlocks, column) {
  return scheduleBlocks.find((block) => {
    return block.day === column.date
      && column.resourceIds.some(id => block.resourceIds.includes(id))
      && block.blockIndex === 0;
  });
}
