import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import ExternalPayment from '@Components/pos/payment/external-payment';
import CardPayment from '@Components/pos/payment/card-payment';
import ReceiptModal from '@Components/pos/payment/receipt-modal';
import DialogPosRefundSelectItems from '@Components/pos/dialogs/dialog-pos-refund-select-items';
import DialogPosRefundConfirm from '@Components/pos/dialogs/dialog-pos-refund-confirm';
import {
  getRefundDialogTitle, getRefundDialogText, getBookingIdForSale,
  isPosUnitOpenOtherDevice, isPosUnitClosed, isPosUnitOpenCurrentDevice, getVunitIdForReceipt
} from '@Utils/pos-utils';
import {
  fetchSaleForRefund, calculateRefund, sendRefund, completeRefund, paymentResult, getRefundReceiptId
} from '@State/pos-actions';
import DialogLoader from '@Components/dialogs/dialog-loader';
import DialogPosNotAvailable from '@Components/pos/dialogs/dialog-pos-not-available';
import { getPosUnitPrefs, getPrinterProgress, hasPrinterError } from '@State/pos-selectors';
import { pos } from '@Utils/preference-keys';
import DialogAlert from '@Components/dialogs/dialog-alert';

const DialogPosRefund = ({ receiptId, receipt, onClose }) => {
  const dispatch = useDispatch();
  const [showDialog, setShowDialog] = useState('SelectedItems');
  const [selectedItems, setSelectedItems] = useState({});
  const [refundData, setRefundData] = useState();
  const [refundMethod, setRefundMethod] = useState();
  const [refundMethodName, setRefundMethodName] = useState();
  const [transactionAmount, setTransactionAmount] = useState();
  const saleId = receipt.get('saleId');
  const sale = useSelector(state => state.posSale.get('sale'));
  const posUnit = useSelector(state => state.pos.get('posUnit'));
  const printerProgress = useSelector(getPrinterProgress);
  const transactionStatus = useSelector(state => state.posSale.get('transactionStatus'));
  const totalRefundedAmount = useSelector(state => state.posSale.get('totalRefundedAmount'));
  const refundReceiptId = useSelector(getRefundReceiptId);
  const posUnitPrefs = useSelector(state => getPosUnitPrefs(state));
  const printerError = useSelector(state => hasPrinterError(state));
  const [returnStock, setReturnStock] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const bookingId = sale && getBookingIdForSale(sale.get('items'));
  const vunitId = useSelector(state => getVunitIdForReceipt(state.receipts, receiptId));

  useEffect(() => {
    if (saleId && isPosUnitOpenCurrentDevice(posUnit)) {
      dispatch(fetchSaleForRefund(saleId, vunitId))
        .then(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  }, [saleId, vunitId]);

  useEffect(() => {
    if (transactionStatus === 'Succeeded' && refundReceiptId) {
      setShowDialog('Receipt');
      setIsLoading(false);
    } else if (transactionStatus === 'Rejected') {
      setShowDialog('Rejected');
      setIsLoading(false);
    }
  }, [transactionStatus, totalRefundedAmount, refundReceiptId]);

  const handleSelectAll = () => {
    const itemsCount = sale?.get('items')?.size || 0;
    const selected = {};

    if (Object.keys(selectedItems).length < itemsCount) {
      sale.get('items').forEach((item) => {
        selected[item.get('itemId')] = {
          amount: item.get('refundableAmount'),
          qty: item.get('refundableQty')
        };
      });
    }
    setSelectedItems(selected);
  };

  const getRefundData = (paymentMethod, terminalId) => {
    const items = Object.keys(selectedItems).map((itemId) => {
      const rows = selectedItems[itemId];
      return {
        itemId,
        qty: rows.qty,
        refundAmount: rows.amount
      };
    });

    return {
      returnStock,
      paymentMethod,
      terminalId,
      items
    };
  };

  const onShowConfirm = () => {
    setIsLoading(true);
    const data = getRefundData();

    dispatch(calculateRefund(saleId, data)).then((refund) => {
      setIsLoading(false);
      setRefundData(refund);
      setShowDialog('Confirm');
    });
  };

  const useIntegration = (paymentMethod) => {
    return paymentMethod === 'SwishMerchant' || paymentMethod === 'KlarnaPayments';
  };

  const onConfirmRefund = (paymentMethod, paymentMethodName, amount) => {
    setRefundMethod(paymentMethod);
    setRefundMethodName(paymentMethodName);
    setTransactionAmount(amount);
    setShowDialog(paymentMethod === 'Card' ? 'CardRefund' : 'Refund');

    if (useIntegration(paymentMethod)) {
      onSendRefund(paymentMethod);
    }
  };

  const onSendRefund = (paymentMethod, terminalId) => {
    const data = getRefundData(paymentMethod, terminalId);

    if (paymentMethod === 'Card') {
      return dispatch(sendRefund(saleId, data));
    }

    setIsLoading(true);
    return dispatch(sendRefund(saleId, data))
      .then(() => {
        if (!useIntegration(paymentMethod)) {
          setIsLoading(false);
          setShowDialog('Receipt');
        }
      })
      .catch(() => {
        setIsLoading(false);
        setShowDialog('Confirm');
      });
  };

  const onCompleteRefund = (data) => {
    return dispatch(completeRefund(data)).then(() => {
      onClose();
    });
  };

  const onShowSelectedItems = () => setShowDialog('SelectedItems');

  const onResetRefundRejection = () => {
    dispatch(paymentResult(posUnit.get('id'), null));
    setShowDialog('Confirm');
  };

  if (isPosUnitOpenOtherDevice(posUnit)) {
    return (
      <DialogPosNotAvailable
        onClose={onClose}
        title="Kassan är öppen på en annan enhet"
        text="Du kan inte använda kassaregistret från två olika enheter samtidigt."
        subText="Stäng kassan på den andra enheten innan du kan öppna den på den här enheter."
      />
    );
  }

  if (isPosUnitClosed(posUnit)) {
    return (
      <DialogPosNotAvailable
        onClose={onClose}
        title="Kassan är stängd"
        text="Öppna kassan för att göra återköp."
      />
    );
  }

  if (isLoading && saleId && !refundReceiptId) {
    return <DialogLoader />;
  }

  switch (showDialog) {
    case 'SelectedItems':
      return (
        <DialogPosRefundSelectItems
          onNext={onShowConfirm}
          onClose={onClose}
          returnStock={returnStock}
          setReturnStock={setReturnStock}
          selectedItems={selectedItems}
          onSetSelectedItems={setSelectedItems}
          handleSelectAll={handleSelectAll}
          items={sale && sale.get('items')}
        />
      );
    case 'Confirm':
      return (
        <DialogPosRefundConfirm
          refundData={refundData}
          onPrev={onShowSelectedItems}
          onSubmit={onConfirmRefund}
          onClose={onClose}
        />
      );
    case 'Refund':
      return (
        <ExternalPayment
          totalAmount={transactionAmount}
          onSubmit={() => onSendRefund(refundMethod)}
          onClose={onShowConfirm}
          title={getRefundDialogTitle(refundMethodName || refundMethod)}
          text={getRefundDialogText(refundMethod)}
          isRefund
        />
      );
    case 'CardRefund':
      return (
        <CardPayment
          saleId={saleId}
          totalAmount={transactionAmount}
          onSubmit={terminalId => onSendRefund('Card', terminalId)}
          onClose={onShowConfirm}
          isRefund
        />
      );
    case 'Receipt':
      return (
        <ReceiptModal
          isRefund
          paymentMethod={refundMethod}
          bookingId={bookingId}
          totalAmount={refundData && refundData.refundableAmount}
          defaultPrinterId={posUnitPrefs[pos.defaultPrinterId]}
          alwaysPrintReceipt={posUnitPrefs[pos.alwaysPrintReceipt]}
          printerProgress={printerProgress}
          printerError={printerError}
          onSubmit={onCompleteRefund}
        />
      );
    case 'Rejected':
      return (
        <DialogAlert
          warning
          icon="fa fa-exclamation-triangle"
          text="Återköpet kunde inte genomföras"
          onClose={onResetRefundRejection}
        />
      );

    case '':
    default: return null;
  }
};

DialogPosRefund.propTypes = {
  onHideDialogs: PropTypes.func
};

export default DialogPosRefund;
