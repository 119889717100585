import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { NEW_BOOKING } from '@Utils/booking-util';
import { getEmbedVersion, isEmbeddedInApp } from '@Utils/embedded-util';
import { createPositionedChips } from './grid-util';
import Chip from './chip';

class ChipColumn extends Component {
  static propTypes = {

    routeParams: PropTypes.object.isRequired,
    bookings: PropTypes.object.isRequired,
    column: PropTypes.object.isRequired,
    columnIndex: PropTypes.number.isRequired,
    gridProps: PropTypes.object.isRequired
  };

  shouldComponentUpdate(nextProps) {
    if (this.props.routeParams !== nextProps.routeParams) {
      return true;
    }
    if (this.props.columnIndex !== nextProps.columnIndex) {
      return true;
    }
    if (this.props.gridProps !== nextProps.gridProps) {
      return true;
    }
    if (this.props.column !== nextProps.column) {
      return true;
    }
    if (!this.props.bookings.equals(nextProps.bookings)) {
      return true;
    }
    return false;
  }

  render() {
    const {
      routeParams, bookings, columnIndex, gridProps, embeddedInApp, embedVersion
    } = this.props;

    const chips = bookings.valueSeq().toJS();
    const chipsWithCoordinates = createPositionedChips(chips, columnIndex, gridProps, routeParams.viewMode);
    return (
      <div>
        {chipsWithCoordinates.map((chip, i) => {
          return (
            <Chip
              {...chip}
              key={chip.id}
              gridProps={gridProps}
              ref={chip.refCallback !== undefined ? chip.refCallback : null}
              routeParams={routeParams}
              colIdx={columnIndex}
              embeddedInApp={embeddedInApp}
              embedVersion={embedVersion}
            />
          );
        })}
      </div>
    );
  }
}

const getBookingsById = state => state.bookingsById;
const getResourceIds = (state, props) => props.column.resourceIds;
const getMomentDate = (state, props) => props.column.date;

const makeGetFilteredBookings = () => {
  return createSelector(
    getBookingsById,
    getResourceIds,
    getMomentDate,
    state => state.gridViewState.get('scheduleEditMode'),
    (bookingsById, resourceIds, momentDate) => {
      const bookings = bookingsById.filter((booking) => {
        if (!booking.resources) {
          throw Error(`Booking has no resources: ${JSON.stringify(booking)}`);
        }
        const isDragger = booking.id === NEW_BOOKING;
        const bookingResourceIds = isDragger
          ? [booking.resourceId]
          : [...booking.resources.map(r => r.id)];

        if (resourceIds.every(id => !bookingResourceIds.includes(id))) {
          return false;
        }
        return booking.startTime.isSame(momentDate, 'day');
      });
      return bookings;
    }
  );
};

const makeMapStateToProps = () => {
  const getFilteredBookings = makeGetFilteredBookings();

  const mapStateToProps = (state, ownProps) => {
    return {
      embeddedInApp: isEmbeddedInApp(state),
      embedVersion: getEmbedVersion(state),
      bookings: getFilteredBookings(state, ownProps)
    };
  };
  return mapStateToProps;
};

export default connect(
  makeMapStateToProps
)(ChipColumn);
