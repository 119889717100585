import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { colors } from '@Components/ui/styled/variables';
import { getClassStatus } from '@Utils/booking-util';
import ConfirmPopover from '@Components/ui/confirm-popover';

const BookingClassStatus = () => {
  const maxSlots = useSelector(state => state.bkf.get('maxSlots'));
  const bookedSlots = useSelector(state => state.bkf.get('bookedSlots'));

  const [showConfirm, setShowConfirm] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  const openColor = isOpen ? colors.greenCircle : colors.danger;
  const status = getClassStatus(bookedSlots, maxSlots);

  const toggleOpen = () => {
    setShowConfirm(false);
    setIsOpen(!isOpen);
  };

  return (
    <div className="booking-class-status">
      <section>
        <h5>
          <span className="color-dot" style={{ background: status.color }} />
          {bookedSlots} av {maxSlots} bokade
        </h5>
        <button type="button" className="btn btn-default">Ändra platser</button>
      </section>
      <section>
        <h5>
          <span className="color-dot" style={{ background: openColor }} />
          {isOpen ? 'Öppen för bokning' : 'Stängd för bokning'}
        </h5>
        {isOpen ? (
          <ConfirmPopover
            text="Vill du stänga klassen för bokning?"
            confirmText="Stäng klass"
            onClose={() => setShowConfirm(false)}
            onConfirm={toggleOpen}
            isOpen={showConfirm}
          >
            <button type="button" className="btn btn-danger" onClick={() => setShowConfirm(true)}>
              Stäng klass
            </button>
          </ConfirmPopover>
        ) : (
          <button type="button" className="btn btn-default" onClick={toggleOpen}>
            Öppna klass
          </button>
        )}
      </section>
    </div>
  );
};

export default BookingClassStatus;
