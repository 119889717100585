import React from 'react';
import ModalDialog from '@Components/dialogs/modal-dialog';
import StaffEmploymentForm from './staff-employment-form';

const StaffEmploymentModal = ({ user, onSubmit, onClose }) => {
  const handleSubmit = (values) => {
    const { employmentType, workHoursPerWeek, workDaysPerWeek, lunchMinutesPerDay } = values;

    return onSubmit({
      employmentType,
      workMinutesPerDay: (workHoursPerWeek * 60) / workDaysPerWeek,
      workDaysPerWeek,
      lunchMinutesPerDay
    });
  };

  const { employmentType, workMinutesPerDay, workDaysPerWeek, lunchMinutesPerDay } = user.employment;
  const initialValues = {
    employmentType,
    workHoursPerWeek: (workMinutesPerDay * workDaysPerWeek) / 60,
    workDaysPerWeek,
    lunchMinutesPerDay
  };

  return (
    <ModalDialog
      title="Anställning"
      hideCloseButton
      closeOnClickOutside={false}
      onClose={onClose}
    >
      <StaffEmploymentForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onClose={onClose}
      />
    </ModalDialog>
  );
};

export default StaffEmploymentModal;
