import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { calendar } from '@Utils/preference-keys';
import { mergeJsonPreferences } from '@State/preferences-actions';
import { calcHeightFromMinutes } from '@Utils/time-util';
import {
  setHighContrast, setGridSize, setExternalKeyboard, setCalendarGridRowsPerHour, setShowChipTooltip
} from '@State/view-actions';
import { DropDownItem, DropdownHeader, DropDownDivider } from '@Components/ui/drop-down/dropdown';
import TBDropdown from '@Components/ui/tb-dropdown';
import { calcGridScrollHeight, calculateGridPixelsPerRow } from '@Components/calendar/grid/grid-state-helper';
import { txt } from '@Utils/i18n-util';
import msg from './calendar-view-settings.msg';

const RowsPerHour = ({ txt, rowsPerHour, gridSize, onClick }) => {
  const rows = [1, 2, 3, 4, 6, 12];
  if (gridSize === 'large') {
    rows.push(15);
  }
  return rows.map(row => {
    return (
      <DropDownItem key={row} onClick={() => onClick(row)} checked={row === rowsPerHour}>
        {txt(msg.minutes, { minutes: 60 / row })}
      </DropDownItem>
    );
  });
};

const CalendarViewSettings = ({
  rowsPerHour,
  gridSize,
  savePref,
  phoneMode,
  dpPosition,
  showHolidays,
  deviceType,
  tabletMode,
  setGridSize,
  highContrast,
  setHighContrast,
  showChipTooltip,
  setShowChipTooltip,
  externalKeyboard,
  confirmMoveEnabled,
  setExternalKeyboard,
  showWebBookingIndicator,
  darkenClosedWebBookingDays,
  setCalendarGridRowsPerHour,
  showAssociatedResource,
  showNotesIndicator,
  useWebBookingColor,
  showPaidBookingBadge,
  alwaysShowTime,
  alwaysShowServiceOnNewLine
}) => {
  const toggleHighContrast = event => {
    event.preventDefault();
    setHighContrast(!highContrast);
  };

  const toggleShowChipTooltip = event => {
    event.preventDefault();
    setShowChipTooltip(!showChipTooltip);
  };

  const toggleGridSize = event => {
    event.preventDefault();
    const newSize = gridSize === 'large' ? 'small' : 'large';
    const prevScrollTop = document.getElementById('gridcontainer').scrollTop;
    setGridSize(newSize);
    setTimeout(() => {
      scrollToSameTime(rowsPerHour, newSize, prevScrollTop);
    }, 1);
  };

  const toggleExternalKeyboard = event => {
    event.preventDefault();
    setExternalKeyboard(!externalKeyboard);
  };

  const toggleWebIndicator = e => {
    e.preventDefault();
    savePref(calendar.showWebBookingIndicator, !showWebBookingIndicator);
  };
  const toggleDarkenDays = e => {
    e.preventDefault();
    savePref(calendar.darkenClosedWebBookingDays, !darkenClosedWebBookingDays);
  };
  const toggleConfirmMove = e => {
    e.preventDefault();
    savePref(calendar.confirmMoveEnabled, !confirmMoveEnabled);
  };
  const toggleDPPosition = e => {
    e.preventDefault();
    savePref(calendar.dpPosition, dpPosition !== 'bottom' ? 'bottom' : 'top');
  };
  const toggleShowHolidays = e => {
    e.preventDefault();
    savePref(calendar.showHolidays, !showHolidays);
  };
  const toggleAlwaysShowTime = e => {
    e.preventDefault();
    savePref(calendar.alwaysShowTime, !alwaysShowTime);
  };
  const toggleAssociatedResource = e => {
    e.preventDefault();
    savePref(calendar.showAssociatedResource, !showAssociatedResource);
  };
  const toggleNotesIndicator = e => {
    e.preventDefault();
    savePref(calendar.showNotesIndicator, !showNotesIndicator);
  };
  const toggleWebBookingColor = e => {
    e.preventDefault();
    savePref(calendar.useWebBookingColor, !useWebBookingColor);
  };
  const togglePaidBookingBadge = e => {
    e.preventDefault();
    savePref(calendar.showPaidBookingBadge, !showPaidBookingBadge);
  };
  const toggleAlwaysShowServiceOnNewLine = e => {
    e.preventDefault();
    savePref(calendar.alwaysShowServiceOnNewLine, !alwaysShowServiceOnNewLine);
  };

  const calcTimeForCoord = (ycoord, day) => {
    const minutesPerRow = 60 / rowsPerHour;
    const pixelsPerRow = calculateGridPixelsPerRow(rowsPerHour, gridSize);
    const minuteOffset = Math.floor(ycoord / pixelsPerRow * minutesPerRow);
    return moment(day).startOf('day').add(minuteOffset, 'minutes');
  };

  const scrollToSameTime = (newRowsPerHour, newGridSize, prevScrollTop) => {
    const currentScrollTime = calcTimeForCoord(prevScrollTop, moment());
    const dayStart = moment(currentScrollTime).startOf('day');
    const minutes = currentScrollTime.diff(dayStart, 'minutes');
    const pixelsPerRow = calculateGridPixelsPerRow(newRowsPerHour, newGridSize);
    const gridScrollHeight = calcGridScrollHeight(pixelsPerRow, newRowsPerHour);
    const newScrollPos = calcHeightFromMinutes({ gridScrollHeight, chipIndentPixels: 0 }, minutes, false);
    document.getElementById('gridcontainer').scrollTop = newScrollPos;
  };

  const setRowsPerHour = newRowsPerHour => {
    const prevScrollTop = document.getElementById('gridcontainer').scrollTop;
    setCalendarGridRowsPerHour(newRowsPerHour).then(
      setTimeout(() => {
        scrollToSameTime(newRowsPerHour, gridSize, prevScrollTop);
      }, 0)
    );
  };

  const isPhoneOrTablet = phoneMode || tabletMode;

  return (
    <TBDropdown label={txt(msg.btnLabel)} icon="fa-cog" phoneMode={phoneMode} tabletMode={tabletMode}>
      <DropDownItem onClick={toggleConfirmMove} checked={confirmMoveEnabled} icon="fa-comment-alt-check">
        {txt(msg.confirmMoveBooking)}
      </DropDownItem>
      {deviceType === 'tablet' && (
        <DropDownItem onClick={toggleExternalKeyboard} checked={externalKeyboard} icon="fa-keyboard">
          {txt(msg.externalKeyboard)}
        </DropDownItem>
      )}
      <DropDownDivider />
      <DropDownItem onClick={toggleHighContrast} checked={highContrast} icon="fa-adjust">
        {txt(msg.highContrast)}
      </DropDownItem>
      <DropDownItem onClick={toggleGridSize} checked={gridSize === 'large'} icon="fa-search">
        {txt(msg.gridSize)}
      </DropDownItem>
      <DropDownDivider />
      {!isPhoneOrTablet
        && (
        <DropDownItem onClick={toggleDPPosition} checked={dpPosition === 'top'} icon="fa-calendar-alt">
          {txt(msg.datePickerPosition)}
        </DropDownItem>
        )}
      <DropDownItem onClick={toggleShowHolidays} checked={showHolidays} icon="fa-umbrella-beach">
        {txt(msg.showHolidays)}
      </DropDownItem>
      <DropDownItem onClick={toggleWebIndicator} checked={showWebBookingIndicator}>
        {txt(msg.webBookingIndicator)}
      </DropDownItem>
      <DropDownItem onClick={toggleDarkenDays} checked={darkenClosedWebBookingDays}>
        {txt(msg.darkenClosedDays)}
      </DropDownItem>
      <DropDownDivider />
      <DropDownItem onClick={toggleAlwaysShowTime} checked={alwaysShowTime} icon="fa-clock">
        {txt(msg.alwaysShowTime)}
      </DropDownItem>
      <DropDownItem onClick={toggleAlwaysShowServiceOnNewLine} checked={alwaysShowServiceOnNewLine} icon="far fa-server">
        {txt(msg.alwaysShowServiceOnNewLine)}
      </DropDownItem>
      <DropDownItem onClick={toggleAssociatedResource} checked={showAssociatedResource} icon="fa-star-of-life">
        {txt(msg.associatedResource)}
      </DropDownItem>
      <DropDownItem onClick={toggleNotesIndicator} checked={showNotesIndicator} icon="fa-info-circle">
        {txt(msg.showNotesIndicator)}
      </DropDownItem>
      <DropDownItem onClick={toggleWebBookingColor} checked={useWebBookingColor} icon="fa-globe">
        {txt(msg.webBookingColor)}
      </DropDownItem>
      <DropDownItem onClick={togglePaidBookingBadge} checked={showPaidBookingBadge} icon="fa-euro">
        {txt(msg.paidBookingBadge)}
      </DropDownItem>
      {!isPhoneOrTablet && (
        <DropDownItem onClick={toggleShowChipTooltip} checked={showChipTooltip}>
          {txt(msg.showChipTooltip)}
        </DropDownItem>
      )}
      <DropDownDivider />
      <DropdownHeader>{txt(msg.calendarInterval)}</DropdownHeader>
      <RowsPerHour txt={txt} rowsPerHour={rowsPerHour} gridSize={gridSize} onClick={setRowsPerHour} />
    </TBDropdown>
  );
};

const mapStateToProps = (state) => {
  const { mainViewState, gridViewState, locationConfig } = state;
  return {
    highContrast: gridViewState.get('highContrast'),
    showChipTooltip: gridViewState.get('showChipTooltip'),
    gridSize: gridViewState.get('gridSize'),
    externalKeyboard: gridViewState.get('externalKeyboard'),
    rowsPerHour: gridViewState.get('rowsPerHour'),
    pixelsPerRow: gridViewState.get('pixelsPerRow'),
    deviceType: mainViewState.get('deviceType'),
    tabletMode: mainViewState.get('tabletMode'),
    phoneMode: mainViewState.get('phoneMode'),
    showWebBookingIndicator: locationConfig.get(calendar.showWebBookingIndicator),
    darkenClosedWebBookingDays: locationConfig.get(calendar.darkenClosedWebBookingDays),
    confirmMoveEnabled: locationConfig.get(calendar.confirmMoveEnabled),
    dpPosition: locationConfig.get(calendar.dpPosition),
    showHolidays: locationConfig.get(calendar.showHolidays),
    showAssociatedResource: locationConfig.get(calendar.showAssociatedResource),
    showNotesIndicator: locationConfig.get(calendar.showNotesIndicator),
    useWebBookingColor: locationConfig.get(calendar.useWebBookingColor),
    showPaidBookingBadge: locationConfig.get(calendar.showPaidBookingBadge),
    alwaysShowTime: locationConfig.get(calendar.alwaysShowTime),
    alwaysShowServiceOnNewLine: locationConfig.get(calendar.alwaysShowServiceOnNewLine)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setHighContrast: enabled => {
      dispatch(setHighContrast(enabled));
    },
    setShowChipTooltip: enabled => {
      dispatch(setShowChipTooltip(enabled));
    },
    setGridSize: newSize => {
      return dispatch(setGridSize(newSize));
    },
    setExternalKeyboard: enabled => {
      dispatch(setExternalKeyboard(enabled));
    },
    setCalendarGridRowsPerHour: rows => {
      const jsonValues = {};
      jsonValues[calendar.rowsPerHour] = rows;
      dispatch(setCalendarGridRowsPerHour(rows)); /* this is to update the UI before the network call finishes */
      return dispatch(mergeJsonPreferences(jsonValues));
    },
    savePref: (key, value) => {
      const jsonValues = {};
      jsonValues[key] = value;
      return dispatch(mergeJsonPreferences(jsonValues));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarViewSettings);
