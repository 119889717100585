import { Map, List, fromJS } from 'immutable';
import { CLEAR_LOCATION_STATE } from '@State/account-actions';
import { POS_PRE_PAYMENTS_FETCHED } from './booking-actions';
import {
  POS_SALES_FETCHED,
  POS_QUEUE_BOOKING,
  POS_ADD_SALE_ITEM,
  POS_REMOVE_SALE_ITEM,
  POS_UPDATE_SALE_ITEM,
  POS_SET_OPERATOR,
  POS_SALE_CREATED,
  POS_SALE_PARKED,
  POS_SALE_UNPARKED,
  POS_SALE_VOIDED,
  POS_SALE_COMPLETED,
  POS_UNITS_FETCHED,
  POS_SET_POS_UNIT,
  POS_PAYMENT_MODAL,
  POS_PAYMENT_RESULT,
  POS_PAYMENT_PROGRESS,
  POS_PAYMENT_PROGRESS_RESET,
  POS_PAYMENT_CHECKSUM_MISMATCH,
  POS_RECEIPT_FETCHED,
  POS_RECEIPTS_FETCHED,
  POS_REPORT_FETCHED,
  POS_REPORTS_FETCHED,
  POS_UPDATE_SALE_ITEM_LOCAL_ID,
  POS_SALE_FETCHED,
  POS_REFUND_COMPLETED,
  POS_RECEIPT_COPY_PRINTED,
  POS_SALE_BOOKING_ADDED,
  POS_RESET_QUEUED_BOOKING,
  POS_SALE_CUSTOMER_ADDED,
  POS_SALE_CUSTOMER_REMOVED,
  POS_TRANSACTIONS_FETCHED,
  POS_TRANSACTIONS_ADDED,
  POS_RECEIPT_COPY_RESET
} from './pos-actions';

import {
  POS_PROGRESS,
  POS_UNIT_UPDATED,
  POS_TERMINAL_READY
} from './pos-config-actions';
import { POS_INVOICE_STATUS_UPDATED } from './invoice-actions';
import { CUSTOMER_GIFT_CARDS_FETCHED } from './customer-actions';

function mapTransaction({ paymentId, paymentMethodName, paymentMethod, paymentProvider, amount, amountReceived }) {
  return {
    paymentId: paymentId || Math.random().toString(16).slice(2),
    paymentMethod: paymentMethodName || paymentMethod || paymentProvider,
    amount: amount || amountReceived
  };
}

export function pos(state = Map(), action = null) {
  switch (action.type) {
    case CLEAR_LOCATION_STATE:
      return state.clear();

    case POS_PROGRESS:
      return state.set('progress', action.progress);

    case POS_PAYMENT_MODAL:
      return state.set('modal', action.modal);

    case POS_SALES_FETCHED:
      return state.withMutations((map) => {
        const open = action.sales?.find(s => s.status !== 'Parked');
        map.set('modal', open ? map.get('modal') : null);
        map.set('sales', fromJS(action.sales));
      });

    case POS_TRANSACTIONS_FETCHED: {
      const { transactions } = action;
      const saleTransactions = {};
      Object.keys(transactions).forEach(saleId => {
        saleTransactions[saleId] = transactions[saleId].map(mapTransaction);
      });
      return state.set('transactions', fromJS(saleTransactions));
    }

    case POS_TRANSACTIONS_ADDED: {
      const { saleId, transactions } = action;
      const saleTransactions = state.getIn(['transactions', String(saleId)]) || List();
      return state.setIn(['transactions', saleId], saleTransactions
        .concat(transactions.map(t => fromJS(mapTransaction(t)))));
    }

    case POS_PRE_PAYMENTS_FETCHED: {
      const { bookingId, prePayments } = action;
      return bookingId
        ? state.setIn(['prePayments', String(bookingId)], fromJS(prePayments))
        : state.set('prePayments', fromJS(prePayments));
    }

    case POS_PAYMENT_RESULT: {
      const { saleId, paymentResult, paymentMethod } = action;
      const key = ['transactions', String(saleId)];
      const saleTransactions = state.getIn(key) || List();

      if (saleId && paymentResult?.payment?.paymentProvider) {
        return state.setIn(key, saleTransactions.push(fromJS(mapTransaction(paymentResult?.payment))));
      }
      // Handle event from Pusher where payment is not included
      if (saleId && paymentResult && paymentMethod) {
        const { amountReceived } = paymentResult;
        return state.setIn(key, saleTransactions.push(fromJS(mapTransaction({ paymentMethod, amountReceived }))));
      }
      return state;
    }

    case POS_SALE_CREATED: {
      const sales = state.get('sales') || List();
      const sale = { id: action.saleId, status: 'Open' };
      return state.delete('queuedBooking').set('sales', sales.push(fromJS(sale)));
    }

    case POS_SALE_VOIDED:
    case POS_SALE_COMPLETED: {
      const index = state.get('sales').findIndex(s => action.saleId === s.get('id'));
      return index !== -1 ? state.removeIn(['sales', index]) : state;
    }

    case POS_SALE_PARKED: {
      const saleId = action.sale.get('id');
      const index = state.get('sales').findIndex(s => saleId === s.get('id'));
      return state.setIn(['sales', index], action.sale.set('status', 'Parked'));
    }

    case POS_SALE_UNPARKED: {
      const index = state.get('sales').findIndex(s => action.sale.get('id') === s.get('id'));
      return state.setIn(['sales', index, 'status'], action.sale.get('status'));
    }

    case POS_SET_POS_UNIT:
      return state.set('posUnit', fromJS(action.posUnit));

    case POS_SET_OPERATOR: {
      if (action.operator) {
        return state.set('operator', action.operator);
      }
      return state.delete('operator').delete('posUnits');
    }

    case POS_UNITS_FETCHED:
      return state.set('posUnits', fromJS(action.units));

    case POS_UNIT_UPDATED: {
      const { vunitId, status, prefs, userAgentFields } = action.unit;
      return state.withMutations((map) => {
        if (vunitId && state.getIn(['posUnit', 'vunitId']) === vunitId) {
          if (prefs) {
            map.setIn(['posUnit', 'prefs'], fromJS(prefs));
          }
          if (userAgentFields) {
            map.setIn(['posUnit', 'userAgentFields'], fromJS(userAgentFields));
          }
          if (status) {
            map.setIn(['posUnit', 'state'], status);
          }
        }
        map.delete('progress');
      });
    }

    case POS_QUEUE_BOOKING:
      return state.set('queuedBooking', action.booking);

    case POS_SALE_BOOKING_ADDED:
    case POS_RESET_QUEUED_BOOKING:
      return state.delete('queuedBooking');

    case POS_PAYMENT_CHECKSUM_MISMATCH:
      return action.error
        ? state.set('checksumMismatch', action.error)
        : state.delete('checksumMismatch');

    default:
      return state;
  }
}

function onSetOperator(state, action) {
  return !action.operator ? state.clear() : state;
}

export function posSale(state = Map(), action = null) {
  switch (action.type) {
    case POS_SALES_FETCHED: {
      const sales = fromJS(action.sales);
      const open = sales.find(s => s.get('status') !== 'Parked');
      return open ? state.clear().merge(open) : state.clear();
    }

    case POS_SALE_FETCHED: {
      return state.set('sale', fromJS(action.sale));
    }

    case POS_SALE_UNPARKED:
      return state.clear().merge(action.sale);

    case POS_SALE_CREATED: {
      const { items, saleId, customer } = action;
      return state.withMutations((map) => {
        if (items) {
          map.set('items', fromJS(items));
        }
        if (customer) {
          map.set('customer', customer);
        }
        map.set('id', saleId);
      });
    }

    case POS_SALE_BOOKING_ADDED: {
      const items = state.get('items').toJS();
      return state.set('bookingId')
        .set('items', fromJS([...items, ...action.items]))
        .set('customer', action.customer || state.get('customer'));
    }

    case POS_SALE_CUSTOMER_ADDED:
      return state.set('customer', action.customer);

    case POS_SALE_CUSTOMER_REMOVED:
      return state.delete('customer');

    case CUSTOMER_GIFT_CARDS_FETCHED:
      return action.saleId
        ? state.set('giftCards', action.giftCards)
        : state;

    case POS_ADD_SALE_ITEM: {
      const items = state.get('items') || List();
      return state.set('items', items.push(fromJS(action.item)));
    }

    case POS_REMOVE_SALE_ITEM: {
      const { itemId, idLocal } = action;
      const index = state.get('items').findIndex(i => {
        return itemId && itemId === i.get('id')
          || idLocal && idLocal === i.get('idLocal');
      });
      return index !== -1 ? state.removeIn(['items', index]) : state;
    }

    case POS_UPDATE_SALE_ITEM_LOCAL_ID: {
      const index = state.get('items').findIndex(i => action.item.idLocal === i.get('idLocal'));
      return state.setIn(['items', index], fromJS({ ...action.item }));
    }

    case POS_UPDATE_SALE_ITEM: {
      const index = state.get('items').findIndex(i => action.item.id === i.get('id')) || 0;
      return state.setIn(['items', index], fromJS(action.item));
    }

    case POS_PAYMENT_RESULT: {
      const {
        transactionStatus, rejectionReason, paymentResult, refundResult,
        paymentMethod, transactionRef, qrCodeUrl, deliveryMethod, warnings,
        fortnoxInvoiceNumber
      } = action;
      if (!paymentResult && !refundResult) {
        return state.merge({
          txProgress: null,
          transactionStatus,
          rejectionReason,
          transactionRef,
          qrCodeUrl
        });
      }

      const {
        newStatus, totalPaidAmount, remainingBalance, totalRefundedAmount,
        receiptId, changeDue, invoiceId, invoiceUrl
      } = paymentResult || refundResult;
      return state.merge({
        paymentMethod,
        txProgress: null,
        prePayments: null,
        transactionStatus,
        rejectionReason,
        status: newStatus,
        paidAmount: totalPaidAmount,
        remainingAmount: remainingBalance,
        totalRefundedAmount,
        receiptId,
        invoiceId,
        invoiceUrl,
        changeDue,
        invoiceMethod: deliveryMethod,
        invoiceWarnings: warnings,
        invoiceNumber: fortnoxInvoiceNumber
      });
    }

    case POS_INVOICE_STATUS_UPDATED: {
      return state.get('invoiceId') === action.invoice.id
        ? state.set('invoiceUrl', action.invoice.invoiceUrl)
        : state;
    }

    case POS_PAYMENT_PROGRESS: {
      const { status, message } = action;
      return state.merge({
        paymentStatus: status || state.get('paymentStatus'),
        paymentMessage: message || state.get('paymentMessage')
      });
    }

    case POS_TERMINAL_READY:
      return state.merge({
        transactionStatus: null,
        rejectionReason: null,
        paymentStatus: null,
        paymentMessage: null
      });

    case POS_REFUND_COMPLETED:
    case POS_PAYMENT_PROGRESS_RESET:
      return state.merge({
        transactionStatus: null,
        rejectionReason: null,
        paymentStatus: null,
        paymentMessage: null,
        receiptId: null
      });

    case POS_SET_OPERATOR:
      return onSetOperator(state, action);

    case POS_SALE_PARKED:
    case POS_SALE_VOIDED:
    case POS_SALE_COMPLETED:
    case CLEAR_LOCATION_STATE:
      return state.clear();

    default:
      return state;
  }
}

export function posReceipt(state = Map(), action = null) {
  switch (action.type) {
    case POS_RECEIPT_FETCHED:
      return fromJS(action.receipt);

    case POS_RECEIPT_COPY_PRINTED: {
      if (state.get('receiptId') === action.receiptId) {
        return state.set('copyCount', 1);
      }
      return state;
    }

    case POS_RECEIPT_COPY_RESET: {
      if (state.get('receiptId') === action.receiptId) {
        return state.set('copyCount', 0);
      }
      return state;
    }

    case POS_PAYMENT_RESULT: {
      const { transactionStatus, refundResult, paymentMethod } = action;
      if (transactionStatus === 'Succeeded' && refundResult) {
        const newTransaction = {
          paymentMethod,
          transactionType: 'Refund',
          transactionAmount: refundResult.refundedAmount
        };
        const transactions = state.get('transactions').push(newTransaction);
        return state.set('transactions', transactions);
      }
      return state;
    }

    case POS_SET_OPERATOR:
      return onSetOperator(state, action);

    default:
      return state;
  }
}

export function posReceipts(state = List(), action = null) {
  switch (action.type) {
    case POS_RECEIPTS_FETCHED: {
      return fromJS(action.receipts);
    }

    case POS_PAYMENT_RESULT: {
      const { transactionStatus, refundResult, paymentMethod } = action;
      const { receiptId, refundedAmount } = refundResult || {};
      if (transactionStatus === 'Succeeded' && receiptId) {
        const newListitem = {
          type: 'Refund',
          receiptId,
          amount: refundedAmount,
          createdTs: new Date().toISOString(),
          paymentMethods: [paymentMethod]
        };
        return state.insert(0, fromJS(newListitem));
      }
      return state;
    }

    case POS_SET_OPERATOR:
      return onSetOperator(state, action);

    default:
      return state;
  }
}

export function posReport(state = Map(), action = null) {
  switch (action.type) {
    case POS_REPORT_FETCHED:
      return fromJS(action.report);

    case POS_SET_OPERATOR:
      return onSetOperator(state, action);

    default:
      return state;
  }
}

export function posReports(state = List(), action = null) {
  switch (action.type) {
    case POS_REPORTS_FETCHED:
      return fromJS(action.reports);

    case POS_SET_OPERATOR:
      return onSetOperator(state, action);

    default:
      return state;
  }
}
