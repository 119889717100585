import React from 'react';
import { sms, email as emailKeys } from '@Utils/preference-keys';
import PhoneUtil from '@Utils/phone-util';
import { txt } from '@Utils/i18n-util';
import msg from './confirm-cancel.msg';

const ConfirmCancel = ({
  onCancel, onConfirm, progress, customer, locationConfig, smsEnabled
}) => {
  const { phoneNumber, email } = customer;
  const showConfirmation = (phoneNumber && smsEnabled) || email;
  const defaultOptionSmsConfirm = locationConfig.get(sms.defaultOptionSmsConfirm);
  const defaultOptionEmailConfirm = locationConfig.get(emailKeys.defaultOptionEmailConfirm);

  return (
    <form autoComplete="off" onSubmit={onConfirm} className={progress ? 'disabled' : ''}>
      <p>
        <strong>{txt(msg.txtConfirmCancel)}</strong>
      </p>
      {showConfirmation && (
        <div>
          <p>
            <strong>{txt(msg.lblSendConfirmation)}</strong>
          </p>
          {phoneNumber && smsEnabled && (
            <div className="checkbox">
              <label>
                <input type="checkbox" name="notifyBySms" value="true" defaultChecked={defaultOptionSmsConfirm} /> {PhoneUtil.formatPhoneNumber(phoneNumber)}
              </label>
            </div>
          )}
          {email && (
            <div className="checkbox">
              <label>
                <input type="checkbox" name="notifyByEmail" value="true" defaultChecked={defaultOptionEmailConfirm} /> {email}
                <br />
              </label>
            </div>
          )}
        </div>
      )}
      <div className="checkbox">
        <label>
          <input type="checkbox" name="deleteBooking" value="true" /> {txt(msg.lblDeleteBooking)}
        </label>
      </div>
      <div className="text-right">
        <button type="button" className="btn btn-default" tabIndex="4" onClick={onCancel}>{txt(msg.btnCancel)}</button> &nbsp;
        <button type="submit" className="btn-save" tabIndex="3">{progress ? txt(msg.btnCancellingProgress) : txt(msg.btnConfirm)}</button>
      </div>
    </form>
  );
};

export default ConfirmCancel;
