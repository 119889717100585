import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useKeyPress from '@Hooks/useKeyPress';
import { fetchBookingReminders, toggleBKFModal } from '@State/bkf/actions';
import { bkfModals } from '@State/bkf/constants';
import { getClassStatus } from '@Utils/booking-util';
import Modal from '@Components/ui/modal';
import { DialogTitleBtn } from '@Components/dialogs/dialog-styles';
import SendSmsModal from '@Components/customers/customer-detail/send-sms/send-sms-modal';
import BookingClassCustomer from './booking-class-customer';
import BookingClassStatus from './booking-class-status';

const customers = [{
  id: 1,
  name: 'Victor Stodell',
  email: 'victor@stodell.se',
  phoneNumber: '+46733425226',
  selected: true
}, {
  id: 2,
  name: 'Henric Larsson',
  email: 'henric@cliento.com',
  phoneNumber: '+46732961010'
}, {
  id: 3,
  name: 'Nina Dobrev',
  email: 'nina@dobrev.com',
  phoneNumber: '+4673281234'
}];

const BookingClassModal = () => {
  const dispatch = useDispatch();
  const bookingId = useSelector(state => state.bkf.get('id'));
  const services = useSelector(state => state.bkf.get('services'));
  const startTime = useSelector(state => state.bkf.get('startTime'));
  const maxSlots = useSelector(state => state.bkf.get('maxSlots'));
  const bookedSlots = useSelector(state => state.bkf.get('bookedSlots'));
  const status = getClassStatus(bookedSlots, maxSlots);

  const [selected, setSelected] = useState([]);
  const [showSendSms, setShowSendSms] = useState(false);
  const allSelected = selected.length === customers.length;
  const noneSelected = selected.length === 0;

  useEffect(() => {
    document.body.classList.add('print-hidden');

    return () => {
      document.body.classList.remove('print-hidden');
    };
  }, []);

  const onSelect = (id) => {
    if (selected.includes(id)) {
      setSelected(selected.filter(s => s !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  const onSelectAll = () => {
    setSelected(allSelected ? [] : customers.map(c => c.id));
  };

  const onSendSms = () => setShowSendSms(true);
  const onCloseForm = () => dispatch(toggleBKFModal(bkfModals.class, false));
  const onFetchReminders = () => dispatch(fetchBookingReminders(bookingId));

  const onPrint = (ev) => {
    ev.preventDefault();
    ev.target.blur();
    window.print();
  };

  useKeyPress(27, () => {
    if (!showSendSms) {
      onCloseForm();
    }
  });

  if (showSendSms) {
    return (
      <SendSmsModal
        customerId={customers[0].id}
        phoneNumber="asdlk"
        onClose={() => setShowSendSms(false)}
      />
    );
  }

  return (
    <Modal
      titleText="Deltagarlista"
      focusTrapPaused
      focusDialog
      underlayClickExits={false}
      mounted
      dialogClass="modal-dialog booking-class-modal-dialog"
    >
      <div className="modal-content">
        <div className="modal-body">
          <div className="pull-right">
            <DialogTitleBtn onClick={onCloseForm}>Stäng</DialogTitleBtn>
          </div>
          <div className="print-visible">
            <h4 className="mt0 mb0">{services.map(s => s.name).join(', ')}</h4>
            <p className="text-muted">{startTime.format('LLLL')}</p>
          </div>
          <BookingClassStatus />
          <h4 className="mt3 mb2">Deltagarlista</h4>
          <div className="booking-class-modal-content" tabIndex={0}>
            <div className="booking-class-customer-header">
              <button type="button" className="btn btn-default" onClick={onSelectAll}>
                <div className="select">
                  <input type="checkbox" checked={allSelected} />
                  Välj alla
                </div>
              </button>
              <div className="options">
                <button type="button" className="btn btn-default" onClick={onSendSms} disabled={noneSelected}>Skicka SMS</button>
                <button type="button" className="btn btn-danger" disabled={noneSelected}>Boka av</button>
                <button type="button" className="btn btn-default" onClick={onPrint}>Skriv ut</button>
              </div>
            </div>
            <div className="print-visible">
              {customers.map(customer => {
                return (
                  <BookingClassCustomer
                    customer={customer}
                    selected={selected.includes(customer.id)}
                    onSelect={() => onSelect(customer.id)}
                  />
                );
              })}
            </div>
          </div>
          <div className="text-right">
            <button type="button" className="btn btn-default" onClick={onCloseForm}>Stäng</button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default BookingClassModal;
