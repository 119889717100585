import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Popover from '@Components/ui/popover';

import { serviceTypes } from '@Utils/service-util';
import { getIsAddonServicesSection } from '@Utils/filter-selectors';
import { hasPermission, CHANGE_GROUPS, CHANGE_RESOURCES } from '@Utils/permissions';
import { addServiceGroup, addService, duplicateService } from '@State/services-actions';
import { getPermissions, getFeatures, getChangeJsonPrefsPermission, getDefaultVatPct } from '@State/selectors';
import { getServiceConfigUrl, navigate } from '@Utils/navigate';
import TBDropdown from '@Components/ui/tb-dropdown';
import { DropdownHeader, DropDownItem } from '@Components/ui/drop-down/dropdown';
import AddGroupForm from '../sortable-list/add-group-form';
import AddItemForm from '../sortable-list/add-item-form';

class ServiceActionsButtons extends Component {
  static propTypes = {
    onAddGroup: PropTypes.func.isRequired,
    onAddService: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      addGroup: false,
      addService: false,
      addGroupColor: false
    };
  }

  render() {
    return (
      <>
        {this.renderAddServiceButton()}
        {this.renderAddGroupButton()}
        {this.renderAdminDropdown()}
      </>
    );
  }

  renderAdminDropdown() {
    const { canChangeJsonPrefs, isAddonServices } = this.props;

    if (!canChangeJsonPrefs || isAddonServices) {
      return null;
    }

    return (
      <TBDropdown icon="fa-tools">
        <DropdownHeader>Verktyg</DropdownHeader>
        <DropDownItem onClick={this.handleDuplicateService} icon="fa-copy">Duplicera tjänst</DropDownItem>
      </TBDropdown>
    );
  }

  renderAddServiceButton() {
    const { permissions, features, orderedServiceGroups, isAddonServices } = this.props;
    const canChangeResource = hasPermission(permissions, CHANGE_RESOURCES);
    const { ShowEditGroupResource } = features;
    const disabled = orderedServiceGroups.isEmpty();

    if (!canChangeResource || !ShowEditGroupResource) {
      return null;
    }

    return (
      <Popover
        isOpen={this.state.addService}
        body={this.addServiceContent()}
        preferPlace="below"
        className="Popover-big-radius"
        onOuterAction={this.handleCloseServicePopover}
      >
        <button
          type="button"
          className="btn btn-cta"
          disabled={disabled}
          onClick={this.handleOpenServicePopover}
        >
          {isAddonServices ? 'Lägg till tilläggstjänst' : 'Lägg till tjänst'}
        </button>
      </Popover>
    );
  }

  renderAddGroupButton() {
    const { permissions, features, isAddonServices } = this.props;
    const canChangeGroups = hasPermission(permissions, CHANGE_GROUPS);
    const { ShowEditGroupResource } = features;

    if (!canChangeGroups || !ShowEditGroupResource || isAddonServices) {
      return null;
    }

    return (
      <Popover
        isOpen={this.state.addGroup}
        body={this.addGroupContent()}
        preferPlace="below"
        className="Popover-big-radius"
        onOuterAction={this.handleCloseGroupPopover}
      >
        <button type="button" className="btn btn-cta-secondary" onClick={this.handleOpenGroupPopover}>Lägg till grupp</button>
      </Popover>
    );
  }

handleOpenGroupPopover = () => {
  this.setState({ addGroup: true });
};

handleCloseGroupPopover = () => {
  if (!this.state.addGroupColor) {
    this.setState({ addGroup: false });
  }
};

handleOpenServicePopover = () => {
  this.setState({ addService: true });
};

handleCloseServicePopover = () => {
  this.setState({ addService: false });
};

handleOpenColorPopover = () => {
  this.setState({ addGroupColor: true });
};

handleCloseColorPopover = () => {
  this.setState({ addGroupColor: false });
};

handleAddGroup = group => {
  return this.props
    .onAddGroup(group)
    .then(() => this.handleCloseGroupPopover());
};

handleAddService = (groupId, name, serviceType) => {
  const { defaultVatPct, isAddonServices } = this.props;
  const defaults = {
    description: '',
    webShowInBooking: true,
    webAllowBooking: true,
    serviceDuration: 60,
    vatPct: defaultVatPct
  };
  return this.props
    .onAddService({ groupId, name, serviceType, addon: isAddonServices, ...defaults })
    .then(() => this.handleCloseServicePopover());
};

handleDuplicateService = () => {
  const { serviceId, service } = this.props;

  return this.props.onDuplicateService(serviceId, service.name);
};

addServiceContent() {
  const { isAddonServices, orderedServiceGroups } = this.props;
  return (
    <AddItemForm
      onClosePopover={this.handleCloseServicePopover}
      onAddItem={this.handleAddService}
      placeholder="Ange namn på tjänsten"
      groups={isAddonServices ? null : orderedServiceGroups.filter(g => !g.get('addon'))}
      types={isAddonServices ? null : serviceTypes}
      typesNews
    />
  );
}

addGroupContent() {
  return (
    <AddGroupForm
      showColorPicker
      onClosePopover={this.handleCloseGroupPopover}
      onAddGroup={this.handleAddGroup}
      colorPickerState={this.state.addGroupColor}
      handleColorPickerOpen={this.handleOpenColorPopover}
      handleColorPickerClose={this.handleCloseColorPopover}
    />
  );
}
}

const mapStateToProps = (state, ownProps) => {
  const serviceId = parseInt(ownProps.id);
  const { servicesById, orderedServiceGroups, adminViewState } = state;
  const service = servicesById && serviceId && servicesById.get(serviceId);
  const groupIds = orderedServiceGroups.map(item => item.get('id')).toJS();

  return {
    groupIds,
    collapsedIds: adminViewState.get('servicesCollapsedIds'),
    orderedServiceGroups,
    permissions: getPermissions(state, ownProps),
    features: getFeatures(state),
    canChangeJsonPrefs: getChangeJsonPrefsPermission(state, ownProps),
    defaultVatPct: getDefaultVatPct(state),
    serviceId,
    service,
    isAddonServices: getIsAddonServicesSection(state, ownProps)
  };
};

const mapDispatchToProps = (dispatch, { routeParams }) => {
  return {
    onAddGroup: (group) => {
      return dispatch(addServiceGroup(group));
    },
    onAddService: (service) => {
      return dispatch(addService(service))
        .then(service => navigate(getServiceConfigUrl(routeParams, service.id)));
    },
    onDuplicateService: (serviceId, name) => {
      return dispatch(duplicateService(serviceId, name))
        .then(srv => navigate(getServiceConfigUrl(routeParams, srv.id)));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceActionsButtons);
