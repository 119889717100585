import moment from 'moment';
import React, { Component } from 'react';
import { getSmsType, getSmsSent } from '@Utils/customer-util';
import { txt } from '@Utils/i18n-util';
import msg from './booking-details.msg';

const BookingDetail = ({ icon, className, children }) => (
  <div>
    <small className={className}>
      <i className={icon} /> {children}
    </small>
  </div>
);
class BookingDetails extends Component {
  render() {
    const {
      id, bookingRef, customer, createdTime, lastUpdateTime, cancelledTime, bookedAs,
      payments, review, reminders, allReminders, intl
    } = this.props;

    const filteredReminders = !allReminders
      ? reminders?.filter(({ type }) => type === 'PreReminder')
      : allReminders;

    const payment = payments?.find(p => p.paymentType === 'Online');
    const { lastReminderSentTs, reviewPostedTs } = review || {};
    const { paidTs, refundTs } = payment || {};
    const { name } = customer || {};
    const isNew = id === 'DRAGGER';

    return (
      <div className="booking-form-details">
        {!isNew && (
          <BookingDetail className="text-muted" icon="far fa-info-circle">
            {txt(msg.bookingRef)}: <strong>{bookingRef}</strong> ({id})
          </BookingDetail>
        )}
        {bookedAs && bookedAs !== name && (
          <BookingDetail className="text-warning" icon="fa fa-user">
            {txt(msg.bookedAs)} {bookedAs}
          </BookingDetail>
        )}
        {createdTime && (
          <BookingDetail className="text-muted" icon={this.channelIcon()}>
            {txt(msg.created)} {moment(createdTime).format('lll')} {this.channelText()}
          </BookingDetail>
        )}
        {lastUpdateTime && (
          <BookingDetail className="text-muted" icon="far fa-clock">
            {txt(msg.updated)} {lastUpdateTime.format('lll')}
          </BookingDetail>
        )}
        {filteredReminders && filteredReminders.map((reminder) => {
          const icon = getSmsSent(reminder.smsStatus) ? 'fa fa-comment-check' : 'far fa-comment-dots';
          return (
            <BookingDetail key={reminder.type} className="text-muted" icon={icon}>
              {this.smsStatus(reminder, intl)}
            </BookingDetail>
          );
        })}
        {!filteredReminders && (
          <BookingDetail className="text-muted" icon="far fa-comment-dots">
            {this.smsStatus({ type: 'PreReminder' }, intl)}
          </BookingDetail>
        )}
        {cancelledTime && (
          <BookingDetail className="text-danger" icon="fa fa-exclamation-circle">
            {txt(msg.cancelled)} {cancelledTime.format('lll')} {this.cancelChannelText()}
          </BookingDetail>
        )}
        {paidTs && (
          <BookingDetail className="text-muted" icon="fa fa-badge-dollar">
            {txt(msg.paid)} {moment(paidTs).format('lll')}
          </BookingDetail>
        )}
        {refundTs && (
          <BookingDetail className="text-muted" icon="fa fa-arrow-circle-left">
            {txt(msg.refunded)} {moment(refundTs).format('lll')}
          </BookingDetail>
        )}
        {lastReminderSentTs && !reviewPostedTs && (
          <BookingDetail className="text-muted" icon="far fa-star">
            {txt(msg.reviewRequestSent)} {moment(lastReminderSentTs).format('lll')}
          </BookingDetail>
        )}
        {reviewPostedTs && (
          <BookingDetail className="text-muted" icon="far fa-star">
            {txt(msg.reviewPosted)} {moment(reviewPostedTs).format('lll')}
          </BookingDetail>
        )}
      </div>
    );
  }

  channelText() {
    switch (this.props.channel) {
      case 'Web':
        return txt(msg.viaWebText);
      case 'WebVacci':
        return txt(msg.viaWebVacci);
      default:
        return txt(msg.viaDefault);
    }
  }

  channelIcon() {
    switch (this.props.channel) {
      case 'Web':
        return 'fa fa-globe';
      case 'WebVacci':
        return 'fa fa-yin-yang';
      default:
        return 'far fa-clock';
    }
  }

  cancelChannelText() {
    switch (this.props.cancelledChannel) {
      case 'Web':
      case 'WebVacci':
        return txt(msg.cancelledViaWeb);
      case 'Cal':
        return txt(msg.cancelledViaCal);
      default:
        return '';
    }
  }

  smsStatus({
    type, status, statusTs, smsStatus, smsStatusTs, sendAfter
  }, intl) {
    const smsType = getSmsType(type, intl);
    const smsStatusDate = moment(statusTs || smsStatusTs).format('lll');

    switch (status || smsStatus) {
      case 'PENDING':
        return `${smsType} ${txt(msg.smsStatusSendAt)} ${moment(sendAfter).format('lll')}`;
      case 'SENT':
        return `${smsType} ${txt(msg.smsStatusSentAt)}  ${smsStatusDate}`;
      case 'DELIVERED':
        return `${smsType} ${txt(msg.smsStatusDeliveredAt)}  ${smsStatusDate}`;
      case 'SEND_REJECTED':
        return `${smsType} ${txt(msg.smsStatusRejected)} `;
      default:
        return `${smsType} ${txt(msg.smsStatusDefault)} `;
    }
  }
}

export default BookingDetails;
