import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import { updateViewDimensions } from '@State/view-actions';
import { initializePusher } from '@Utils/pusher';
import { deployEnvironment } from '@Utils/config';
import { isEmbeddedInApp } from '@Utils/embedded-util';
import { getLocationName } from '@State/selectors';

import AccountStatusBanner from '@Components/dialogs/account-status-banner';
import NoResourcePromptsContainer from '@Components/dialogs/no-resource-prompts';
import AccountInfoAlertContainer from '@Components/dialogs/account-info-alert';
import NetworkErrorAlertContainer from '@Components/dialogs/network-error-alert';
import VersionMismatchAlert from '@Components/dialogs/version-mismatch-alert';
import FeaturesOnboardingModal from '@Components/onboarding/features-onboarding-modal';

import Nav from '@Components/nav/nav';
import Header from '@Components/nav/header';
import CalendarHeader from '@Components/calendar/header/calendar-header';
import PosHeader from '@Components/pos/header/pos-header';
import CustomerHeader from '@Components/customers/customer-header';
import CampaignHeader from '@Components/campaign/campaign-header';
import AdminHeader from '@Components/admin/admin-header';
import UserConfigHeader from '@Components/user-config/user-config-header';
import ReportsHeader from '@Components/reports/reports-header';
import StaffHeader from '@Components/staff/staff-header';

import Calendar from '@Components/calendar/calendar';
import Staff from '@Components/staff/staff';
import Customers from '@Components/customers/customers';
import Reports from '@Containers/reports';
import Campaign from '@Containers/campaign';
import LocationLoader from '@Components/location-selection/LocationLoader';
import Pos from '@Components/pos/pos';
import Admin from '@Components/admin/admin';
import UserConfig from '@Containers/user-config';
import OperatorAutoLogout from '@Components/pos/operator-auto-logout';
import BarcodeScanner from '@Utils/barcode';
import FortnoxAuth from '@Components/dialogs/fortnox-auth';
import StripeAuth from '@Components/dialogs/stripe-auth';
import AppVersionTooLowAlert from '@Components/dialogs/app-version-too-low-alert';
import MyBusinessAuth from '@Components/dialogs/my-business-auth';

class MainView extends Component {
  constructor(props) {
    super(props);
    this.setSentryUserFromProps(props);
    this.setPageTitleFromProps(props);
    this.props.initializePusher();
    this.updateDimensions = debounce(this.props.updateDimensions, 200);
  }

  handleResize = () => {
    this.updateDimensions();
  };

  handleOrientationChange = () => {
    setTimeout(() => {
      this.props.updateDimensions();
    }, 300);
  };

  UNSAFE_componentWillMount() {
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('orientationchange', this.handleOrientationChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('orientationchange', this.handleOrientationChange);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.org !== nextProps.org
      || this.props.loc !== nextProps.loc
      || this.props.username !== nextProps.username
    ) {
      this.props.initializePusher();
      this.setPageTitleFromProps(nextProps);
      this.setSentryUserFromProps(nextProps);
    }
  }

  setPageTitleFromProps(props) {
    const { locationName } = props;
    const env = deployEnvironment();
    let t;

    switch (env) {
      case 'Local':
        t = `🔷 ${locationName}`;
        break;
      case 'Preview':
        t = `🔶 ${locationName}`;
        break;
      default:
        t = locationName;
    }

    document.title = t;
  }

  setSentryUserFromProps(props) {
    if (window.Sentry) {
      const { username, org, loc } = props;
      console.log('Setting user context', username, org, loc);
      window.Sentry.configureScope((scope) => {
        scope.setUser({ email: username, org, loc });
      });
    } else {
      console.warn('Sentry not found, not setting user context');
    }
  }

  render() {
    return this.props.embeddedInApp ? this.renderMobileAppView() : this.renderFullView();
  }

  renderFullView() {
    return (
      <main className="main-container">
        <BarcodeScanner />
        <VersionMismatchAlert />
        <NoResourcePromptsContainer />
        <NetworkErrorAlertContainer />
        <FeaturesOnboardingModal />

        <Route path="/:org/:loc" component={AccountStatusBanner} />
        <Route path="/:org/:loc" component={AccountInfoAlertContainer} />
        <Route path="/:org/:loc/:section" component={OperatorAutoLogout} />
        <Route path="/:org/:loc/:section/:subsection?" component={Nav} />
        <Route path="/:org/:loc/fortnox-auth" component={FortnoxAuth} />
        <Route path="/:org/:loc/my-business-auth" component={MyBusinessAuth} />
        <Route path="/:org/:loc/stripe-auth" component={StripeAuth} />

        <section className="section-container">
          <Switch>
            <Route path="/:org/:loc/:section(calendar)/:viewMode/:viewDate/:entityType/:entityId" component={CalendarHeader} />
            <Route path="/:org/:loc/:section(pos)/:subsection?/:id?" exact component={PosHeader} />
            <Route path="/:org/:loc/:section(customers)/:id?" component={CustomerHeader} />
            <Route path="/:org/:loc/:section(staff)/:id?" component={StaffHeader} />
            <Route path="/:org/:loc/:section(campaign)/:id?" component={CampaignHeader} />
            <Route path="/:org/:loc/:section(admin)/:subsection?/:id?/:tab?" component={AdminHeader} />
            <Route path="/:org/:loc/:section(reports)/:subsection?/:id?" component={ReportsHeader} />
            <Route path="/:org/:loc/:section(user-config)/" component={UserConfigHeader} />
            <Route path="/:org/:loc/:section/:subsection?" component={Header} />
          </Switch>
          <Switch>
            <Route path="/:org/:loc/:section(calendar)/" exact component={LocationLoader} />
            <Route path="/:org/:loc/:section(customers)/:id?/:tab?/:tabId?" component={Customers} />
            <Route path="/:org/:loc/:section(staff)/:id?" component={Staff} />
            <Route path="/:org/:loc/:section(pos)/:subsection?/:id?" component={Pos} />
            <Route path="/:org/:loc/:section(reports)/:subsection?/:id?" component={Reports} />
            <Route path="/:org/:loc/:section(campaign)/:id?" component={Campaign} />
            <Route path="/:org/:loc/:section(user-config)/" component={UserConfig} />
            <Route path="/:org/:loc/:section(admin)/:subsection?/:id?/:tab?" component={Admin} />
            <Route path="/:org/:loc/:section(calendar)/:viewMode/:viewDate/:entityType/:entityId" component={Calendar} />
          </Switch>
        </section>
      </main>
    );
  }

  renderMobileAppView() {
    return (
      <main className="main-container">
        <VersionMismatchAlert />
        <AppVersionTooLowAlert />

        <Route path="/:org/:loc" component={AccountStatusBanner} />
        <Route path="/:org/:loc" component={AccountInfoAlertContainer} />

        <section className="section-container">
          <Switch>
            <Route path="/:org/:loc/:section(calendar)/" exact component={LocationLoader} />
            <Route path="/:org/:loc/:section(calendar)/:viewMode/:viewDate/:entityType/:entityId" component={Calendar} />
            <Route>
              <h1>Embedded 404</h1>
            </Route>
          </Switch>
        </section>
      </main>
    );
  }
}

MainView.propTypes = {
  org: PropTypes.string.isRequired,
  loc: PropTypes.string.isRequired,
  initializePusher: PropTypes.func.isRequired,
  updateDimensions: PropTypes.func.isRequired,
  locationName: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const { authState } = state;
  const { org, loc } = ownProps.match.params;

  return {
    org,
    loc,
    username: authState.get('username'),
    locationName: getLocationName(state),
    embeddedInApp: isEmbeddedInApp(state)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { org, loc } = ownProps.match.params;

  return {
    updateDimensions: () => {
      dispatch(updateViewDimensions());
    },
    initializePusher: () => {
      initializePusher(dispatch, org, loc);
    }
  };
};

const MainViewContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainView);

export default MainViewContainer;
