import React, { Component } from 'react';
import { connect } from 'react-redux';
import CurrencyUtil from '@Utils/currency-util';
import { toggleProductsDelivery } from '@State/admin-view-reducer';
import { setProductStocked } from '@State/products-actions';
import LabelSwitchInput from '@Components/ui/label-switch-input';
import Button from '@Components/ui/button';
import ProductDeliveriesModal from './product-deliveries-modal';

class ProductStock extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editDeliveries: false
    };
  }

  onShowDelivery = (direction) => {
    this.props.onToggleDelivery(this.props.productId, direction);
  };

  onEditDeliveries = () => this.setState({ editDeliveries: true });

  onCloseEdit = () => this.setState({ editDeliveries: false });

  onSetStocked = (stocked) => {
    return this.props.onSetStocked(this.props.productId, stocked);
  };

  render() {
    const { productId, name, priceIn, stocked, stockQuantity } = this.props;
    const { editDeliveries } = this.state;

    return (
      <>
        <h4>Lagerhantering</h4>
        <LabelSwitchInput
          noBorder
          label="Aktivera lagerhantering"
          id="product-stocked"
          isOn={stocked}
          handleToggle={() => this.onSetStocked(!stocked)}
        />
        {stocked && (
          <div className="mt2">
            <div className="form-group">
              <label className="control-label">Lagersaldo</label>
              <div>{stockQuantity || 0}</div>
            </div>
            <div className="form-group">
              <label className="control-label">Inköpspris (exkl. moms)</label>
              <div>{priceIn ? CurrencyUtil.accountCurrency(priceIn) : '-'}</div>
              <span className="form-control-description">Beräknat snittvärde av inköpspris på hela lagersaldot, enligt FIFO principen
                <br /><br /><a href="https://vismaspcs.se/ekonomiska-termer/vad-ar-fifo" target="_blank">Läs mer om FIFO principen här</a>
              </span>
            </div>
            <div className="mt2">
              <Button primary small marginRight marginTop onClick={() => this.onShowDelivery('in')}>Inleverans</Button>
              <Button gray small marginRight marginTop disabled={stockQuantity === 0} onClick={() => this.onShowDelivery('out')}>Utleverans</Button>
              <Button gray small marginTop onClick={this.onEditDeliveries}>
                <i className="far fa-wrench mr1" />
                Korrigera
              </Button>
            </div>
          </div>
        )}
        {editDeliveries && (
          <ProductDeliveriesModal
            name={name}
            productId={productId}
            stockQuantity={stockQuantity}
            priceIn={priceIn}
            onClose={this.onCloseEdit}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state, { productId }) => {
  const { products } = state;
  const product = productId && products && products.get(productId);

  return {
    name: product?.name || '',
    priceIn: product?.priceIn || 0,
    stocked: product?.stocked || false,
    stockQuantity: product?.stocked ? product.stockQuantity : 0
  };
};

const mapDispatchToProps = dispatch => ({
  onToggleDelivery: (delivery, direction) => {
    return dispatch(toggleProductsDelivery(delivery, direction));
  },
  onSetStocked: (productId, stocked) => {
    return dispatch(setProductStocked(productId, stocked));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductStock);
