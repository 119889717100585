import { defineMessages } from 'react-intl';

export default defineMessages({
  calendar: {
    id: 'app.navSectionItems.calendar',
    defaultMessage: 'Calendar'
  },
  pos: {
    id: 'app.navSectionItems.pos',
    defaultMessage: 'Payment'
  },
  reports: {
    id: 'app.navSectionItems.reports',
    defaultMessage: 'Reports'
  },
  staff: {
    id: 'app.navSectionItems.staff',
    defaultMessage: 'Staff\nJournal'
  },
  customers: {
    id: 'app.navSectionItems.customers',
    defaultMessage: 'Customers'
  },
  campaign: {
    id: 'app.navSectionItems.campaign',
    defaultMessage: 'Campaign'
  },
  admin: {
    id: 'app.navSectionItems.admin',
    defaultMessage: 'Admin'
  }
});
