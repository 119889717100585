import React from 'react';
import orderBy from 'lodash/orderBy';
import { getTotal } from '@Components/reports/reports-helpers';
import { reportingCount, reportingFormat } from '@Utils/format';

const renderSaleStaffRow = ({ name, saleCount, netAmount }, index) => (
  <tr key={index}>
    <td>{name}</td>
    <td className="col-xs-3 text-right">{reportingCount(saleCount)}</td>
    <td className="col-xs-3 text-right">{reportingFormat(netAmount)}</td>
    <td className="col-xs-3 text-right">{reportingFormat(netAmount / saleCount)}</td>
  </tr>
);

const SalesReportStaffRows = ({ staffSales }) => {
  const staffList = orderBy(staffSales, ['name'], ['asc']);
  const totalSaleCount = getTotal(staffSales, 'saleCount');
  const totalNetAmount = getTotal(staffSales, 'netAmount');

  return (
    <table className="table table-condensed table-report">
      <thead>
        <tr>
          <th>Namn</th>
          <th className="col-xs-3 text-right">Antal köp</th>
          <th className="col-xs-3 text-right">Inkl. moms</th>
          <th className="col-xs-3 text-right">Snittbelopp</th>
        </tr>
      </thead>
      <tbody>
        {staffList.map(renderSaleStaffRow)}
      </tbody>
      <tfoot>
        <tr>
          <th>Summa</th>
          <th className="col-xs-3 text-right">{reportingCount(totalSaleCount)}</th>
          <th className="col-xs-3 text-right">{reportingFormat(totalNetAmount)}</th>
          <th className="col-xs-3 text-right">{totalSaleCount > 0 ? reportingFormat(totalNetAmount / totalSaleCount) : '-'}</th>
        </tr>
      </tfoot>
    </table>
  );
};

export default SalesReportStaffRows;
