import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextareaAutosize from 'react-textarea-autosize';
import {
  Field, reduxForm, formValueSelector, change
} from 'redux-form';
import { FormattedNumber } from 'react-intl';
import { customer, web } from '@Utils/preference-keys';
import { getWebPaymentEnabled } from '@State/selectors';
import SubmitButton from '@Components/ui/submit-button';
import RichTextEditorField from '../../inputs/rich-text-editor';
import WebBookingStrategy from '../../inputs/web-booking-strategy';

const stylesMarginZero = { marginBottom: 0 };
const stylesMarginTopZero = { marginTop: 0 };
const stylesMarginWrap = { marginBottom: 10, marginTop: 10 };

class WebBookingForm extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.requireNotes && nextProps.requireNotes) {
      this.props.setFormValue(web.hideNotes, false);
    }
    if (!this.props.showResourceStep && nextProps.showResourceStep) {
      this.props.setFormValue(web.autoResourceSelection, false);
    }
    if (this.props.showTCBox && !nextProps.showTCBox) {
      this.props.setFormValue(web.showExtraTCBox, false);
    }
  }

  render() {
    const {
      handleSubmit, smsPinPrice, features, requireNotes, allowCancellation,
      slotFinderStrategy, interval, showTCBox, showExtraTCBox, showResourceStep,
      paymentEnabled, requireEmail, requirePNo, showAllowMarketing
    } = this.props;

    return (
      <form autoComplete="off" className="form-horizontal" onSubmit={handleSubmit}>

        <div className="row">
          <div className="col-sm-offset-3 col-sm-9">
            <h3>Inställningar för onlinebokningen</h3>
          </div>
        </div>

        <div className="form-group" style={stylesMarginZero}>
          <label className="col-sm-3 control-label">Algoritm</label>
          <div className="col-sm-6">
            <WebBookingStrategy propertyName={web.slotFinderStrategy} slotFinderStrategy={slotFinderStrategy} interval={interval} />
          </div>
        </div>

        <hr />

        <div className="form-group">
          <label className="col-sm-3 control-label">Tillåt bokning senast</label>
          <div className="col-sm-4">
            <Field name={web.minTimeBeforeBooking} component="input" type="number" className="form-control" required min={0} />
          </div>
          <p className="col-sm-4 form-control-static">minuter innan</p>
        </div>

        <div className="form-group">
          <label className="col-sm-3 control-label">Tillåt bokning upp till</label>
          <div className="col-sm-4">
            <Field name={web.bookingMaxDaysInAdvance} component="input" type="number" className="form-control" required min={0} max={365} />
          </div>
          <p className="col-sm-4 form-control-static">dagar innan</p>
        </div>

        {features.ShowBookingRules && (
          <div>
            <div className="form-group">
              <div className="col-sm-offset-3 col-sm-6">
                <div className="checkbox">
                  <label>
                    <Field name={web.allowCancellation} component="input" type="checkbox" />
                    Tillåt avbokning via webben
                  </label>
                </div>
              </div>
            </div>

            {allowCancellation && (
              <div className="form-group">
                <label className="col-sm-3 control-label">Avbokning senast</label>
                <div className="col-sm-4">
                  <Field name="cancelUpToHours" component="input" type="number" className="form-control" required min={0} />
                </div>
                <p className="col-sm-4 form-control-static">timmar innan</p>
              </div>
            )}

            <hr />

            <div className="form-group">
              <label className="col-sm-3 control-label">Benämning på resurs</label>
              <div className="col-sm-4">
                <Field name={web.resourceLabel} component="input" type="text" className="form-control" />
              </div>

              <div className="col-sm-offset-3 col-sm-6" style={stylesMarginWrap}>
                <span className="text-muted">
                  Ändra benämningen på resurser i onlinebokningen, t.ex. om du vill att det ska stå Frisör istället för Person
                </span>
              </div>
            </div>

            <hr />

            <div className="form-group">
              <label className="col-sm-3 control-label">Funktioner</label>
              <div className="col-sm-6">
                <div className="well well-sm">
                  {features.EnableSmsPin && features.useSmsVerification && (
                    <div className="checkbox">
                      <label>
                        <Field name={web.useSmsVerification} component="input" type="checkbox" />
                        Bekräfta bokning med SMS-kod (
                        {smsPinPrice != null && <FormattedNumber style="currency" currency="SEK" value={smsPinPrice} />}
                        )
                      </label>
                      <div className="checkbox-description">
                        Skickar SMS med en kod som måste anges för att slutföra bokningen
                      </div>
                    </div>
                  )}
                  <div className="checkbox">
                    <label>
                      <Field name={web.allowMultipleServices} component="input" type="checkbox" />
                      Tillåt bokning av flera tjänster
                    </label>
                    <div className="checkbox-description">
                      Kunder kan boka flera tjänster på samma bokning
                    </div>
                  </div>
                  <div className="checkbox">
                    <label>
                      <Field name={web.alwaysShowDescription} component="input" type="checkbox" />
                      Visa alltid beskrivning för tjänster
                    </label>
                    <div className="checkbox-description">
                      Tar bort möjligheten att gömma beskrivningen för tjänster
                    </div>
                  </div>
                  <div className="checkbox">
                    <label>
                      <Field name={web.collapseGroups} component="input" type="checkbox" />
                      Kollapsa grupper (tjänster)
                    </label>
                    <div className="checkbox-description">
                      Döljer tjänsterna i grupperna tills användaren väljer att visa dem, om du har många tjänster så får man
                      en bättre överblick med kollapsade grupper.
                    </div>
                  </div>
                  <div className="checkbox">
                    <label>
                      <Field name={web.showUnmappedServices} component="input" type="checkbox" />
                      Visa även tjänster som inte går att boka online
                    </label>
                    <div className="checkbox-description">
                      Kan användas tex för att visa en tjänst kopplad till en resurs som inte har onlinebokning aktiverat
                    </div>
                  </div>
                  <div className="checkbox">
                    <label>
                      <Field name={web.skipServiceStep} component="input" type="checkbox" />
                      Hoppa över val av tjänst om det bara finns en
                    </label>
                    <div className="checkbox-description">
                      Väljer automatiskt den enda tillgängliga tjänsten om det endast finns
                      en bokningsbar tjänst
                    </div>
                  </div>
                  <div className="checkbox">
                    <label>
                      <Field name={web.showResourceStep} component="input" type="checkbox" />
                      Resurs måste väljas vid bokning
                    </label>
                    <div className="checkbox-description">
                      Visar val av resurs som första steg i bokningen
                    </div>
                  </div>
                  <div className="checkbox">
                    <label>
                      <Field name={web.autoResourceSelection} component="input" type="checkbox" disabled={showResourceStep} />
                      Välj resurs automatiskt
                    </label>
                    <div className="checkbox-description">
                      Gömmer valet av resurs och väljer automatiskt en resurs som är ledig vid vald tid
                    </div>
                  </div>
                  <div className="checkbox">
                    <label>
                      <Field name={web.mergeDuplicateResources} component="input" type="checkbox" />
                      Slå ihop resurser med samma namn
                    </label>
                    <div className="checkbox-description">
                      Visar flera resurser med samma namn i onlinebokningen som en resurs
                    </div>
                  </div>
                  <div className="checkbox">
                    <label>
                      <Field name={web.showOneSlotPerTime} component="input" type="checkbox" />
                      Slå ihop lediga tider för samma tidpunkt
                    </label>
                    <div className="checkbox-description">
                      Om flera resurser är lediga vid samma tidpunkt så visas bara en tid och resurs väljs automatiskt
                    </div>
                  </div>
                  <div className="checkbox">
                    <label>
                      <Field name={web.gotoNextAvailableTime} component="input" type="checkbox" />
                      Gå direkt till nästa lediga tid
                    </label>
                    <div className="checkbox-description">
                      Navigera automatiskt till nästa lediga tid om det inte finns någon tid denna vecka
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr />

            <div className="form-group">
              <label className="col-sm-3 control-label">Kunduppgifter</label>
              <div className="col-sm-6">
                <div className="well well-sm">
                  <div className="checkbox">
                    <label>
                      <input type="checkbox" checked disabled />
                      Namn måste anges
                    </label>
                  </div>
                  <div className="checkbox checkbox-child">
                    <label>
                      <Field name={web.separateNameFields} component="input" type="checkbox" />
                      Ange för- och efternamn i separata fält
                    </label>
                  </div>
                  <div className="checkbox">
                    <label>
                      <input type="checkbox" checked disabled />
                      Mobilnummer måste anges
                    </label>
                  </div>
                  <div className="checkbox checkbox-child">
                    <label>
                      <Field name={web.confirmPhone} component="input" type="checkbox" />
                      Bekräfta mobilnummer med dubbla fält
                    </label>
                    <label>
                      <Field
                        name={web.bookingsPerMobileLimit}
                        component="input"
                        type="checkbox"
                        format={value => value === 1}
                        normalize={value => value ? 1 : 0}
                      />
                      Endast en bokning i taget per mobilnummer
                    </label>
                  </div>
                  <div className="checkbox">
                    <label>
                      <Field name={web.requireEmail} component="input" type="checkbox" />
                      Mejladress måste anges
                    </label>
                  </div>
                  <div className="checkbox checkbox-child">
                    <label>
                      <Field name={web.confirmEmail} component="input" type="checkbox" disabled={!requireEmail} />
                      Bekräfta mejladress med dubbla fält
                    </label>
                  </div>
                  <div className="checkbox">
                    <label>
                      <Field name={web.requirePNo} component="input" type="checkbox" />
                      Personnummer måste anges
                    </label>
                  </div>
                  <div className="checkbox checkbox-child">
                    <label>
                      <Field name={web.allowForeignPno} component="input" type="checkbox" disabled={!requirePNo} />
                      Tillåt reserv- och samordningsnummer samt utländska personnummer
                    </label>
                    <label>
                      <Field
                        name={web.bookingsPerPnoLimit}
                        component="input"
                        type="checkbox"
                        disabled={!requirePNo}
                        format={value => value === 1}
                        normalize={value => value ? 1 : 0}
                      />
                      Endast en bokning i taget per personnummer
                    </label>
                  </div>

                  {features.EnableCompanyBooking && (
                    <>
                      <div className="checkbox">
                        <label>
                          <Field name={web.requireOrgName} component="input" type="checkbox" />
                          Företagsnamn måste anges
                        </label>
                      </div>
                      <div className="checkbox">
                        <label>
                          <Field name={web.requireOrgNo} component="input" type="checkbox" />
                          Organisationsnummer måste anges
                        </label>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            <hr />

            <div className="form-group">
              <label className="col-sm-3 control-label">Meddelande</label>
              <div className="col-sm-8">
                <div className={requireNotes ? 'checkbox disabled' : 'checkbox'}>
                  <label>
                    <Field name={web.hideNotes} component="input" type="checkbox" />
                    Dölj meddelandefältet
                  </label>
                </div>
                <div className="checkbox">
                  <label>
                    <Field name={web.requireNotes} component="input" type="checkbox" />
                    Meddelande måste anges vid bokning
                  </label>
                </div>
              </div>
            </div>

            <div className="form-group">
              <label className="col-sm-3 control-label">Rubrik</label>
              <div className="col-sm-4">
                <Field name={web.customMessageLabel} component="input" type="text" className="form-control" />
              </div>
              <div className="col-sm-offset-3 col-sm-6" style={stylesMarginWrap}>
                <span className="text-muted">
                  Ändra benämningen på meddelandefältet i onlinebokningen, t.ex. "Särskilda önskemål"
                </span>
              </div>
            </div>

            <div className="form-group">
              <label className="col-sm-3 control-label">Beskrivning</label>
              <div className="col-sm-8">
                <Field name={web.messageExtraInfo} component={this.textArea} />
              </div>

              <div className="col-sm-offset-3 col-sm-8" style={stylesMarginWrap}>
                <span className="text-muted">
                  Lägg till information ovanför meddelandefältet, t.ex. instruktioner om vad som ska fyllas i
                </span>
              </div>
            </div>

            <hr />
            <div className="form-group">
              <label className="col-sm-3 control-label">Extra information</label>
              <div className="col-sm-8">
                <Field name={web.bookingExtraInfo} component={RichTextEditorField} />
              </div>

              <div className="col-sm-offset-3 col-sm-6" style={stylesMarginWrap}>
                <p className="text-muted">
                  Lägg till en text i sista steget på bokningen, t.ex. avbokningsregler och villkor
                </p>
                <div className="checkbox">
                  <label>
                    <Field name={web.showExtraInfoOnConfirmPage} component="input" type="checkbox" />
                    Visa även i steget efter att bokningen slutförts
                  </label>
                </div>
              </div>
            </div>
            <hr />

            {allowCancellation && (
              <>
                <div className="form-group">
                  <label className="col-sm-3 control-label">Information vid avbokning</label>
                  <div className="col-sm-8">
                    <Field name={web.cancellationPageInfo} component={RichTextEditorField} />
                  </div>

                  <div className="col-sm-offset-3 col-sm-6" style={stylesMarginWrap}>
                    <p className="text-muted">
                      Visa extra information på avbokningssidan, t.ex. avbokningsregler och villkor
                    </p>
                  </div>
                </div>
                <hr />
              </>
            )}

            <div className="form-group">
              <label className="col-sm-3 control-label">Godkänn villkor</label>
              <div className="col-sm-8">
                <div className="checkbox">
                  <label>
                    <Field name={web.showTCBox} component="input" type="checkbox" />
                    Bokningsvillkor måste godkännas
                  </label>
                  <div className="checkbox-description">
                    Lägger till en ruta med texten "Jag godkänner villkoren"
                    som måste bockas i för att kunna boka
                  </div>
                </div>
              </div>
            </div>

            {showTCBox && (
              <>
                <div className="form-group">
                  <label className="col-sm-3 control-label">Egen text för att godkänna villkor</label>
                  <div className="col-sm-8">
                    <Field name={web.customTCBoxLabel} component={RichTextEditorField} simple />
                  </div>
                  <div className="col-sm-offset-3 col-sm-6" style={stylesMarginTopZero}>
                    <p className="text-muted">
                      Byt ut texten "Jag godkänner villkoren" mot en egen text, t.ex. med länk till villkor.
                    </p>
                  </div>
                </div>
                <hr />

                <div className="form-group">
                  <label className="col-sm-3 control-label">Godkänn extra villkor</label>
                  <div className="col-sm-8">
                    <div className="checkbox">
                      <label>
                        <Field name={web.showExtraTCBox} component="input" type="checkbox" />
                        Extra villkor måste godkännas
                      </label>
                      <div className="checkbox-description">
                        Lägger till en extra ruta med villkor
                        som måste bockas i för att kunna boka
                      </div>
                    </div>
                  </div>
                </div>

                {showExtraTCBox && (
                  <div className="form-group">
                    <label className="col-sm-3 control-label">Text för extra villkor</label>
                    <div className="col-sm-8">
                      <Field name={web.extraTCBoxLabel} component={RichTextEditorField} simple />
                    </div>
                    <div className="col-sm-offset-3 col-sm-9 mt2">
                      <div className="checkbox">
                        <label>
                          <Field name={web.showExtraTCBoxInVerifyStep} component="input" type="checkbox" />
                          Visa i sista steget innan bokningen bekräftas
                        </label>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}

            <hr />
            <div className="form-group">
              <label className="col-sm-3 control-label">Godkänn marknadsföring</label>
              <div className="col-sm-8">
                <div className="checkbox">
                  <label>
                    <Field name={web.showAllowMarketing} component="input" type="checkbox" />
                    Marknadsföring måste godkännas
                  </label>
                  <div className="checkbox-description">
                    Lägger till en extra ruta för att godkänna marknadsföring
                  </div>
                </div>
              </div>
            </div>

            {showAllowMarketing && (
              <>
                <div className="form-group">
                  <label className="col-sm-3 control-label">Text för att godkänna marknadsföring</label>
                  <div className="col-sm-8">
                    <Field name={web.allowMarketingLabel} component={RichTextEditorField} simple />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-sm-3 control-label" />
                  <div className="col-sm-8">
                    <div className="checkbox">
                      <label>
                        <Field name={web.allowMarketingDefaultChecked} component="input" type="checkbox" />
                        Godkänn marknadsföring är förvalt
                      </label>
                    </div>
                  </div>
                </div>
              </>
            )}

            {paymentEnabled && (
              <>
                <hr />
                <div className="form-group">
                  <label className="col-sm-3 control-label">Betalningsvillkor</label>
                  <div className="col-sm-8">
                    <Field name={web.paymentTerms} component={RichTextEditorField} simple />
                  </div>
                  <div className="col-sm-offset-3 col-sm-6 text-muted">
                    Visa text i betalningssteget, t.ex. med länk till villkor.
                  </div>
                </div>
              </>
            )}

            <hr />
          </div>
        )}

        <div className="form-group">
          <label className="col-sm-3 control-label">Koppla ny kund till resurs vid bokning</label>
          <div className="col-sm-4">
            <div className="select-container">
              <Field name={customer.associateWithResource} component="select" className="form-control">
                <option value="Always">Ja, för alla resurser</option>
                <option value="FreelanceOnly">Ja, endast för frilans/hyrstol</option>
                <option value="Manual">Nej, alla kunder tillhör kontot</option>
              </Field>
            </div>
          </div>
        </div>

        <div className="form-group">
          <label className="col-sm-3 control-label">Koppla ny bokning till befintlig kund</label>
          <div className="col-sm-8">
            <div className="radio">
              <label>
                <Field name={web.customerMatchingCriteria} component="input" type="radio" value="PhoneNumber" />
                Om telefonnummer matchar
              </label>
            </div>
            <div className="radio">
              <label>
                <Field name={web.customerMatchingCriteria} component="input" type="radio" value="PhoneNumberAndName" />
                Om både telefonnummer och namn matchar
              </label>
            </div>
          </div>
        </div>

        <hr />

        <div className="form-group">
          <div className="col-sm-offset-3 col-sm-6">
            <SubmitButton {...this.props} />
          </div>
        </div>
      </form>
    );
  }

  textArea = props => (
    <TextareaAutosize {...props.input} minRows={2} className="form-control" />
  );
}

const selector = formValueSelector('web-booking-preferences');

const mapStateToProps = (state) => {
  return {
    paymentEnabled: getWebPaymentEnabled(state),
    slotFinderStrategy: selector(state, web.slotFinderStrategy),
    requireNotes: selector(state, web.requireNotes),
    allowCancellation: selector(state, web.allowCancellation),
    showResourceStep: selector(state, web.showResourceStep),
    showTCBox: selector(state, web.showTCBox),
    showExtraTCBox: selector(state, web.showExtraTCBox),
    showAllowMarketing: selector(state, web.showAllowMarketing),
    requireEmail: selector(state, web.requireEmail),
    requirePNo: selector(state, web.requirePNo),
    interval: selector(state, 'interval')
  };
};

const mapDispatchToProps = dispatch => ({
  setFormValue: (property, value) => {
    dispatch(change('web-booking-preferences', property, value));
  }
});

export default reduxForm({
  form: 'web-booking-preferences',
  enableReinitialize: true
})(connect(mapStateToProps, mapDispatchToProps)(WebBookingForm));
