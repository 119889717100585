import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router';
import { getSectionUrl } from '@Utils/navigate';
import { getRouteParams, getWebPaymentEnabled } from '@State/selectors';
import { setUserPreference } from '@State/user-actions';
import { fetchCashiers } from '@State/users-actions';
import {
  getAvailableReports, reportsQuerySelector, getReportDateText,
  getReportParams, getReportContext, getReportTitle, getCurrentReport
} from '@Components/reports/reports-helpers';
import {
  getBookingList,
  getBookingReport,
  getSmsReport,
  getStaffReport,
  getSalesReport,
  getStockReports,
  getReportDataFields,
  getReportDataOptions,
  getDeferredSalesReport,
  getPrePaymentsReport,
  getGiftCardReport,
  getGiftCardRedeems
} from '@State/report-actions';
import { fetchProductGroups } from '@State/products-actions';
import { fetchGroupsAndResources } from '@State/resource-actions';
import { fetchPosOrgs } from '@State/pos-config-actions';
import { fetchStaff } from '@State/staff-actions';
import SalesReportToolbar from '@Components/reports/sales/sales-report-toolbar';
import DeferredSalesReportToolbar from '@Components/reports/sales/deferred-sales-report-toolbar';
import PrePaymentsReportToolbar from '@Components/reports/sales/pre-payments-report-toolbar';
import FeatureNotAvailable from '@Components/dialogs/feature-not-available';
import BookingReport from '@Components/reports/booking/booking-report';
import BookingList from '@Components/reports/booking/booking-list';
import ReportsFilter from '@Components/reports/reports-filter';
import ReportsSettings from '@Components/reports/reports-settings';
import SalesReport from '@Components/reports/sales/sales-report';
import DeferredSalesReport from '@Components/reports/sales/deferred-sales-report';
import PrePaymentsReport from '@Components/reports/sales/pre-payments-report';
import GiftCardReport from '@Components/reports/gift-cards/gift-card-report';
import StockReport from '@Components/reports/stock/stock-report';
import StaffReport from '@Components/reports/staff/staff-report';
import DataExport from '@Components/reports/data-export/data-export';
import CustomerReport from '@Components/reports/customer/customer-report';
import StaffReportSidebar from '@Components/reports/staff/staff-report-sidebar';
import StockReportSidebar from '@Components/reports/stock/stock-report-sidebar';
import SmsReport from '@Components/reports/sms/sms-report';
import NavTabsLink from '@Components/ui/nav-tabs-link';
import SubNav from '@Components/nav/sub-nav';
import Loader from '@Components/ui/loader';
import Button from '@Components/ui/button';

class Reports extends Component {
  state = {
    loading: true,
    collapsed: false,
    initialized: false
  };

  componentDidMount() {
    const { resourcesById, staffState, enablePos, paymentEnabled } = this.props;

    const methods = [this.props.getExportReportData()];
    if (resourcesById.isEmpty()) {
      methods.push(this.props.fetchGroupsAndResources());
    }
    if (staffState.isEmpty()) {
      methods.push(this.props.fetchStaff());
    }
    if (enablePos || paymentEnabled) {
      methods.push(this.props.fetchPosOrgs());
      methods.push(this.props.fetchCashiers());
    }

    this.setState({ initialized: false });
    Promise.all(methods)
      .then(() => this.loadReportWithLoader(this.props))
      .finally(() => this.setState({ initialized: true }));
  }

  toggleCollapse = (ev) => {
    ev.target.blur();
    this.setState(({ collapsed }) => ({ collapsed: !collapsed }));
  };

  handlePrint = (ev) => {
    ev.preventDefault();
    ev.target.blur();
    window.print();
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { routeParams, currentReport, match, reportsQuery } = this.props;

    const subsectionChanged = nextProps.routeParams.subsection !== routeParams.subsection;
    const tabChanged = currentReport?.tabs && nextProps.match.params.id !== match.params.id;
    const queryChanged = nextProps.reportsQuery !== reportsQuery;

    const initialRedirect = nextProps.routeParams.subsection && !routeParams.subsection;
    const filterUpdate = this.state.initialized && (subsectionChanged || tabChanged || queryChanged);

    if (filterUpdate || initialRedirect) {
      this.loadReportWithLoader(nextProps);
    }
    if (subsectionChanged && this.props.tabletMode) {
      this.setState({ collapsed: true });
    }
  }

  render() {
    const { routeParams, sections, enableReports, location } = this.props;
    const { collapsed } = this.state;
    const sidebarClass = 'subnav-sidebar hidden-print';

    if (!enableReports) {
      return (
        <div className="section-content">
          <FeatureNotAvailable
            leadText="Rapporter och statistik är inte tillgängligt för ditt abonnemang"
            routeParams={routeParams}
            showLink
          />
        </div>
      );
    }

    return (
      <div className="subnav">
        <div className={collapsed ? `${sidebarClass} collapsed` : sidebarClass}>
          <div className="nav-toggle-collapse">
            <button className="btn btn-default" onClick={this.toggleCollapse}>
              <i className={collapsed ? 'fa fa-chevron-right' : 'fa fa-chevron-left'} />
            </button>
          </div>
          {enableReports && (
            <SubNav routeParams={routeParams} sections={sections} query={location?.search} />
          )}
        </div>
        <div className="subnav-content">
          <div className="subnav-container">
            {this.renderFeature()}
          </div>
        </div>
      </div>
    );
  }

  renderPrintButton = (currentReport) => {
    return currentReport?.showPrint
      ? <Button onClick={this.handlePrint} gray small>Skriv ut</Button>
      : null;
  }

  getRoutePath = (subsection) => {
    return `/:org/:loc/:section(reports)/:subsection(${subsection})/:id?`;
  }

  renderFeature() {
    const {
      routeParams, reportDateText, reportContext, reportTitle,
      currentReport, location, match
    } = this.props;
    const { subsection } = routeParams;
    const { loading } = this.state;

    const defaultUrl = getSectionUrl(routeParams, 'reports', 'bookings');
    const { hideFilter, showPrint, tabs } = currentReport || {};
    const showfilter = !hideFilter && !match.params.id || tabs;
    const showTitle = !match.params.id && subsection !== 'stock' || tabs;

    if (currentReport?.tabs && !match.params.id) {
      return <Redirect to={getSectionUrl(routeParams, `reports/${subsection}`, currentReport.tabs[0].navId)} />;
    }

    return (
      <div className="columns-container">
        <Switch>
          <Route path={this.getRoutePath('staff')} component={StaffReportSidebar} />
          <Route path={this.getRoutePath('stock')} component={StockReportSidebar} />
        </Switch>
        <div className="columns-content">
          <div className="columns-content-container width-large">
            {loading && <Loader />}
            <div className="columns-content-body with-padding">
              {tabs && (
                <NavTabsLink
                  withoutId
                  bottomMargin
                  subUrl={`reports/${subsection}`}
                  routeParams={match.params}
                  query={location.search}
                  sections={tabs}
                />
              )}
              {showfilter && (
                <div className="hidden-print">
                  <div className="report-buttons">
                    <Switch>
                      <Route path={this.getRoutePath('booking-list')} component={ReportsSettings} />
                      <Route path={this.getRoutePath('sales')} component={SalesReportToolbar} />
                      <Route path={this.getRoutePath('deferred-sales')} component={DeferredSalesReportToolbar} />
                      <Route path={this.getRoutePath('pre-payments')} component={PrePaymentsReportToolbar} />
                    </Switch>
                    {this.renderPrintButton(currentReport)}
                  </div>
                  <ReportsFilter routeParams={routeParams} location={location} match={match} />
                </div>
              )}
              {!loading && (
                <>
                  {showTitle && (
                    <div className="row">
                      <div className="col-xs-6">
                        <h3 className={hideFilter ? 'mt0 mb3' : 'mb3'}>
                          {reportTitle}<br />
                          {reportContext && <small>{reportContext}</small>}
                          {hideFilter && showPrint && <small>{reportDateText}</small>}
                        </h3>
                      </div>
                      <div className="col-xs-6 text-right">
                        {hideFilter
                          ? this.renderPrintButton(currentReport)
                          : <h3 className="date-header">{reportDateText}</h3>}
                      </div>
                    </div>
                  )}
                  <Switch>
                    <Route exact path="/:org/:loc/reports/" render={() => <Redirect to={defaultUrl} />} />
                    <Route path={this.getRoutePath('bookings')} component={BookingReport} />
                    <Route path={this.getRoutePath('booking-list')} component={BookingList} />
                    <Route path={this.getRoutePath('sales')} component={SalesReport} />
                    <Route path={this.getRoutePath('deferred-sales')} component={DeferredSalesReport} />
                    <Route path={this.getRoutePath('pre-payments')} component={PrePaymentsReport} />
                    <Route path={this.getRoutePath('gift-cards')} component={GiftCardReport} />
                    <Route path={this.getRoutePath('sms')} component={SmsReport} />
                    <Route path={this.getRoutePath('stock')} component={StockReport} />
                    <Route path={this.getRoutePath('staff')} component={StaffReport} />
                    <Route path={this.getRoutePath('booking-export')} component={DataExport} />
                    <Route path={this.getRoutePath('customer-export')} component={CustomerReport} />
                  </Switch>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  loadReportWithLoader(props) {
    this.setState({ loading: true });
    this.loadReport(props)
      .finally(() => this.setState({ loading: false }));
  }

  loadReport(props) {
    const { match, reportsQuery, reportParams } = props;
    const { group, resource } = reportsQuery;
    const { posOrgId, userId, start, end, dateType } = reportParams;
    const { subsection, id } = match.params;

    switch (subsection) {
      case 'sms':
        return this.props.getSmsReport(start, end);

      case 'staff':
        return this.props.getStaffReport(start, end);

      case 'sales':
        if (posOrgId) {
          return this.props.getSalesReport(posOrgId, start, end, userId);
        }
        break;

      case 'deferred-sales':
        return this.props.getDeferredSalesReport(start, end);

      case 'pre-payments':
        return this.props.getPrePaymentsReport(start, end);

      case 'gift-cards':
        if (posOrgId && id === 'redeemed') {
          return this.props.getGiftCardRedeems(posOrgId, start, end);
        }
        if (posOrgId && id === 'ledger') {
          return this.props.getGiftCardReport(posOrgId, start);
        }
        break;

      case 'bookings':
        return this.props.getBookingReport(start, end, group?.id, resource?.id, dateType);

      case 'booking-list':
        return this.props.getBookingList(start, end, group?.id, resource?.id);

      case 'stock':
        return this.props.getStockReports();
    }
    return Promise.resolve();
  }
}

Reports.propTypes = {
  resourcesById: PropTypes.object.isRequired,
  enableReports: PropTypes.bool.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const { cashiersById, resourcesById, staffState, locationFeatures } = state;

  return {
    cashiersById,
    resourcesById,
    staffState,
    currentReport: getCurrentReport(state, ownProps),
    reportsQuery: reportsQuerySelector(state, ownProps),
    reportParams: getReportParams(state, ownProps),
    routeParams: getRouteParams(state, ownProps),
    enableReports: locationFeatures.get('EnableReports'),
    enablePos: locationFeatures.get('EnablePOS'),
    paymentEnabled: getWebPaymentEnabled(state),
    sections: getAvailableReports(state, ownProps),
    reportDateText: getReportDateText(state, ownProps),
    reportContext: getReportContext(state, ownProps),
    reportTitle: getReportTitle(state, ownProps),
    location: ownProps.location
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserPreference: (forLocation, prefs) => dispatch(setUserPreference(forLocation, prefs)),
    fetchGroupsAndResources: () => dispatch(fetchGroupsAndResources()),
    fetchPosOrgs: () => dispatch(fetchPosOrgs()),
    fetchCashiers: () => dispatch(fetchCashiers()),
    fetchStaff: () => dispatch(fetchStaff()),
    getSmsReport: (start, end) => dispatch(getSmsReport(start, end)),
    getStaffReport: (start, end) => dispatch(getStaffReport(start, end)),
    getBookingList: (start, end, groupId, resourceId) => dispatch(getBookingList(start, end, groupId, resourceId)),
    getBookingReport: (start, end, groupId, resourceId, dateType) => dispatch(getBookingReport(start, end, groupId, resourceId, dateType)),
    getSalesReport: (posOrgId, start, end, userId) => dispatch(getSalesReport(posOrgId, start, end, userId)),
    getDeferredSalesReport: (start, end) => dispatch(getDeferredSalesReport(start, end)),
    getPrePaymentsReport: (start, end) => dispatch(getPrePaymentsReport(start, end)),
    getGiftCardReport: (posOrgId, date) => dispatch(getGiftCardReport(posOrgId, date)),
    getGiftCardRedeems: (posOrgId, start, end) => dispatch(getGiftCardRedeems(posOrgId, start, end)),
    getStockReports: () => Promise.all([
      dispatch(fetchProductGroups()),
      dispatch(getStockReports())
    ]),
    getExportReportData: () => Promise.all([
      dispatch(getReportDataFields()),
      dispatch(getReportDataOptions())
    ])
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
