import axios from 'axios';
import { pick } from 'ramda';
import { destroy, initialize } from 'redux-form';
import {
  prefixUrl, axiosDefault, checkStatusAxios, axiosErrorHandler
} from '@Utils/ajax-util';
import { resetHighlightedBookings } from '@State/view-actions';
import { resourceFromColIdx } from '@Components/calendar/grid/grid-state-helper';
import {
  bookingAdded, bookingChanged, bookingDeleted,
  revertBooking
} from '@State/booking-actions';
import PhoneUtil from '@Utils/phone-util';

import {
  RESET_BKF,
  SET_BKF_VEHICLE,
  SET_BKF_COMPANY,
  SET_BKF_CUSTOMER,
  SET_BKF_BOOKING,
  SET_BKF_SERVICE,
  ADD_BKF_SERVICE,
  REMOVE_BKF_SERVICE,
  UPDATE_BKF_SERVICE,
  ADD_BKF_RESOURCE,
  REMOVE_BKF_RESOURCE,
  RESET_BKF_NEW_RESOURCE,
  SET_BKF_TIME,
  SET_BKF_RES_TIME,
  SET_BKF_ATTRIBUTES,
  TOGGLE_BKF_MODAL,
  RES_SRV_LOADED,
  RES_SRV_LOADING,
  CLEAR_RES_SRV,
  BOOKING_SALE_LOADED,
  BOOKING_REMINDERS_LOADED
} from './constants';

export function clearFormsBKF() {
  return (dispatch) => {
    dispatch(destroy(
      'bkf-company', 'bkf-customer', 'bkf-custom-fields', 'bkf-service', 'bkf-address', 'bkf-notes',
      'bkf-confirmation', 'bkf-place', 'bkf-vehicle', 'bkf-price', 'bkf-time', 'bkf-class-info'
    ));
  };
}

export function clearAndCloseBKF() {
  return (dispatch, getState) => {
    dispatch({ type: RESET_BKF });
    dispatch(clearFormsBKF());

    setTimeout(() => {
      // Don't reset highlight if booking form was closed because of a click on another chip
      const { bkf } = getState();
      if (!bkf.get('id')) {
        dispatch(resetHighlightedBookings());
      }
    }, 100);
  };
}

export function setBKFBooking(booking, colIdx, sourceResourceId) {
  return {
    type: SET_BKF_BOOKING,
    booking,
    colIdx,
    sourceResourceId
  };
}

export function setBKFAttributes(attributes) {
  return {
    type: SET_BKF_ATTRIBUTES,
    attributes
  };
}

export function updateBKFCoords(coords, routeParams) {
  return (dispatch, getState) => {
    const state = getState();
    const { id, startTime, endTime, colIdx } = coords;
    const resource = resourceFromColIdx(state, routeParams, colIdx);

    dispatch(destroy('bkf-time'));
    dispatch({
      type: SET_BKF_RES_TIME,
      id,
      startTime,
      endTime,
      resId: id === 'DRAGGER' && resource
        ? resource.id
        : state.bkf.get('resourceId')
    });
  };
}

export function setBKFBookingTime(newTime) {
  return {
    type: SET_BKF_TIME,
    ...newTime
  };
}

export function loadBKFBooking(bkId, colIdx, sourceResourceId) {
  return (dispatch, getState) => {
    const booking = getState().bookingsById.get(bkId);
    dispatch(clearFormsBKF());
    dispatch(setBKFBooking(booking, colIdx, sourceResourceId));
  };
}

export function setBKFVehicle(vehicle) {
  return (dispatch) => {
    dispatch(initialize('bkf-vehicle', vehicle));
    dispatch({ type: SET_BKF_VEHICLE, vehicle });
  };
}

export function addBKFService(service) {
  return (dispatch) => {
    dispatch(destroy('bkf-service', 'bkf-price', 'bkf-time'));
    dispatch({ type: ADD_BKF_SERVICE, service });
  };
}

export function removeBKFService(service) {
  return (dispatch) => {
    dispatch(destroy('bkf-service', 'bkf-price', 'bkf-time'));
    dispatch({ type: REMOVE_BKF_SERVICE, service });
  };
}

export function updateBKFService(service) {
  return (dispatch) => {
    dispatch(destroy('bkf-service', 'bkf-price'));
    dispatch({ type: UPDATE_BKF_SERVICE, service });
  };
}

export function addBKFResource(resource) {
  return (dispatch) => {
    dispatch({ type: ADD_BKF_RESOURCE, resource });
  };
}

export function resetBKFNewResource(resource) {
  return (dispatch) => {
    dispatch({ type: RESET_BKF_NEW_RESOURCE, resource });
  };
}

export function removeBKFResource(resource) {
  return (dispatch) => {
    dispatch({ type: REMOVE_BKF_RESOURCE, resource });
  };
}

export function setBKFService(service) {
  return (dispatch) => {
    dispatch(destroy('bkf-service', 'bkf-price', 'bkf-time'));
    dispatch({ type: SET_BKF_SERVICE, service });
  };
}

export function setBKFCompany(company) {
  return (dispatch) => {
    dispatch(destroy('bkf-company'));
    dispatch({ type: SET_BKF_COMPANY, company });
  };
}

export function setBKFCustomer(customer) {
  return (dispatch) => {
    const initialValues = customer && {
      ...customer,
      phoneNumber: PhoneUtil.formatPhoneNumber(customer.phoneNumber),
      otherPhoneNumber: PhoneUtil.formatPhoneNumber(customer.otherPhoneNumber)
    };
    dispatch(initialize('bkf-customer', initialValues));
    dispatch({ type: SET_BKF_CUSTOMER, customer });
  };
}

export function updateBKFCustomer(id, customer) {
  return (dispatch, getState) => {
    const { bkf } = getState();
    const bkfCustomer = bkf.get('customer');

    if (bkfCustomer?.customerId === id) {
      const fields = ['name', 'pno', 'email', 'phoneNumber', 'otherPhoneNumber'];
      const updatedCustomer = pick(fields, customer);
      dispatch(setBKFCustomer({
        ...bkfCustomer,
        ...updatedCustomer
      }));
    }
  };
}

export function toggleBKFModal(modal, show) {
  return {
    type: TOGGLE_BKF_MODAL,
    modal,
    show
  };
}

function loadingResourceServices() {
  return {
    type: RES_SRV_LOADING
  };
}

function resourceServicesLoaded(resId, services) {
  return {
    type: RES_SRV_LOADED,
    resId,
    services
  };
}

export function clearResourceServices() {
  return {
    type: CLEAR_RES_SRV
  };
}

export function addBooking(booking) {
  const url = prefixUrl(`/bookings/resource/${booking.resourceId}/`);
  const config = axiosDefault();
  return (dispatch) => {
    return axios.post(url, booking, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => {
        const newBooking = { ...booking, ...res.data };
        dispatch(bookingAdded(newBooking, 'local'));
        return newBooking;
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function updateBooking(booking) {
  const url = prefixUrl(`/bookings/resource/${booking.resourceId}/${booking.id}`);
  const config = axiosDefault();

  return (dispatch) => {
    return axios.put(url, booking, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => dispatch(bookingChanged(booking.id, { ...booking, ...res.data }, 'local')))
      .catch(error => axiosErrorHandler(error, dispatch, () => dispatch(revertBooking(booking.id))));
  };
}

export function deleteBooking(id) {
  const url = prefixUrl(`/bookings/${id}`);
  const config = axiosDefault();

  return (dispatch) => {
    return axios.delete(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(req => dispatch(bookingDeleted(id, 'local')))
      .catch(error => axiosErrorHandler(error, dispatch, () => dispatch(revertBooking(id))));
  };
}

export function fetchResourceServices(resId, clearCache) {
  const url = prefixUrl(`/services/resource-services/${resId}`);
  const config = axiosDefault();

  return (dispatch) => {
    if (clearCache) {
      dispatch(clearResourceServices());
    }
    dispatch(loadingResourceServices());

    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then((res) => {
        dispatch(resourceServicesLoaded(resId, res.services));
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function fetchSuggestions(query, scope, distinct) {
  if (!query || !scope || query.length <= 2) {
    return Promise.resolve([]);
  }

  const queryParam = `?query=${encodeURIComponent(query)}`;
  const scopeParam = `&scope=${scope.join(',')}`;
  const distinctParam = distinct ? '&distinct=true' : '';

  const url = prefixUrl(`/search${queryParam}${scopeParam}${distinctParam}`);
  const config = axiosDefault();

  return axios.get(url, config)
    .then(({ data }) => data.customerEntries)
    .catch((error) => {
      console.error('Could not get suggestions', error);
      return [];
    });
}

export function fetchVehicleInfo(regNo) {
  const url = prefixUrl(`/search-vehicle?regNo=${encodeURIComponent(regNo)}`);
  const config = axiosDefault();

  return (dispatch) => {
    return axios.get(url, config)
      .then(({ data }) => {
        dispatch(setBKFVehicle({
          vehicleRegNo: regNo,
          vehicleDescription: data.summary,
          vehicleAttributes: data.attribs
        }));
      });
  };
}

export function fetchBookingSale(bookingId) {
  return (dispatch) => {
    const url = prefixUrl(`/bookings/${bookingId}/sale`);
    const config = axiosDefault();

    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => dispatch({ type: BOOKING_SALE_LOADED, sale: res.data }))
      .catch((error) => {
        console.error('Failed to fetch booking sale', { bookingId });
        Sentry.captureException(error);
      });
  };
}

export function fetchBookingReminders(bookingId) {
  return (dispatch) => {
    const url = prefixUrl(`/reminders/${bookingId}/`);
    const config = axiosDefault();

    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => dispatch({ type: BOOKING_REMINDERS_LOADED, reminders: res.data }))
      .catch(() => {});
  };
}

export function fetchBookingEvents(bookingId) {
  return (dispatch) => {
    const url = prefixUrl(`/bookings/${bookingId}/history`);
    const config = axiosDefault();

    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data.events)
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}
