import React, { useState } from 'react';
import moment from 'moment';
import CurrencyUtil from '@Utils/currency-util';
import { getVehicleInfo } from '@Utils/vehicle-util';
import DialogPosRefund from '@Components/pos/dialogs/dialog-pos-refund';
import {
  getCustomerDetails, getDiscountDescription, getItemDescription, getPaymentMethodName, saleItemSort
} from '@Utils/pos-utils';
import {
  Button, Panel, Text, Cell, Row
} from '@Components/ui/styled/main';
import {
  PanelWrapper, CcuFooter, RefundItemWrap, RefundStyle, RowItem, SummaWrap, ReportTitle, ReportTitleButtons
} from '@Components/pos/style';
import ReceiptTools from './receipt-tools';

const styleTextAmount = { marginRight: '40px' };

const renderTransactionsRow = (id, title, value) => (
  <Text key={id}>
    <Row>
      <Cell>{title}</Cell>
      <Cell pullRight>{value}</Cell>
    </Row>
  </Text>
);

export const renderReceiptItemsSubRow = (quantity, description, itemType, value) => (
  <Row>
    <Cell width="30px">{quantity}</Cell>
    <Cell>{getItemDescription(description, itemType)}</Cell>
    <Cell pullRight>{value}</Cell>
  </Row>
);

const renderCommentSubRow = (comment) => (
  <Row>
    <Cell width="30px" />
    <Cell><span className="text-muted">{comment}</span></Cell>
  </Row>
);

export const renderReceiptItemsRow = (item, cashierName) => {
  const {
    id, itemType, description, quantity, totalAmount, discountType, discountAmount, discountValue,
    comment, sellerName, voucherBeneficiary, voucherCode, vehicleRegNo, vehicleDescription
  } = item.toJS();
  const isDiscount = itemType === 'Discount';
  const vehicleInfo = getVehicleInfo(vehicleRegNo, vehicleDescription);

  return (
    <RowItem key={id}>
      {!isDiscount && (<Text>{renderReceiptItemsSubRow(quantity, description, itemType, CurrencyUtil.accountCurrency(totalAmount, 2))}</Text>)}
      {discountAmount !== 0 && (
        <Text>
          {renderReceiptItemsSubRow(null, getDiscountDescription(discountValue, discountType), itemType, CurrencyUtil.accountCurrency(discountAmount))}
        </Text>
      )}
      {cashierName !== sellerName && renderCommentSubRow(`Säljare: ${sellerName}`)}
      {comment && renderCommentSubRow(comment)}
      {vehicleInfo && renderCommentSubRow(vehicleInfo)}
      {voucherBeneficiary && renderCommentSubRow(voucherBeneficiary)}
      {voucherCode && renderCommentSubRow(voucherCode)}
    </RowItem>
  );
};

const Receipt = ({
  receiptId, receipt, showSendReceipt, handleSelectReceipt, printReceipt
}) => {
  const [isPrinting, setIsPrinting] = useState(false);
  const [isShowDialog, setIsShowDialog] = useState(false);
  const hasReceipt = receipt && !receipt.isEmpty();

  const isRefund = hasReceipt && receipt.get('transactionType') === 'Refund';
  const isShowRefundBtn = hasReceipt && receipt.get('saleStatus') !== 'Refunded' && !isRefund && receipt.get('paidAmount') > 0;
  const canPrintReceipt = !isPrinting && hasReceipt && receipt.get('copyCount') === 0;

  const onShowDialog = () => setIsShowDialog(true);
  const onHideDialog = () => setIsShowDialog(false);

  const onPrintReceipt = () => {
    setIsPrinting(true);
    return printReceipt()
      .then(() => setIsPrinting(false));
  };

  let refundTransactions,
    salesTransactions;

  if (hasReceipt) {
    const transactions = receipt.get('transactions').toJS();
    refundTransactions = transactions && transactions.filter(({ transactionType }) => transactionType === 'Refund');
    if (!isRefund) {
      salesTransactions = transactions && transactions.filter(({ transactionType }) => transactionType === 'Sale');
    }
  }

  const getDateText = (createdTs) => `${moment(createdTs).format('D MMMM YYYY')} kl ${moment(createdTs).format('HH:mm')}`;
  const titleText = isRefund ? 'Returkvitto' : 'Kvitto';
  const isInvoice = receipt.get('receiptType')?.includes('Invoice');
  const customerDetails = getCustomerDetails(receipt.get('customerName'), receipt.get('customerPno'));

  return hasReceipt ? (
    <>
      {isShowDialog && <DialogPosRefund onClose={onHideDialog} receiptId={receiptId} receipt={receipt} />}
      <ReportTitle>
        <h3 className="mt0 mb0">
          {isInvoice
            ? `Faktura #${receipt.get('receiptNumber')}`
            : `${titleText} #${receipt.get('receiptNumber')}`}
        </h3>
        <ReportTitleButtons>
          {isInvoice ? (
            <a href={receipt.get('pdfUrl')} target="_blank" className={receipt.get('pdfUrl') ? 'btn btn-default' : 'hidden'}>
              <i className="far fa-file-alt mr1" /> Ladda ner faktura
            </a>
          ) : (
            <>
              {isShowRefundBtn && <Button small marginRight primary onClick={onShowDialog}>Återköp</Button>}
              <Button small marginRight gray onClick={showSendReceipt} disabled={!canPrintReceipt}>Mejla</Button>
              <Button small gray onClick={onPrintReceipt} disabled={!canPrintReceipt}>Skriv ut</Button>
            </>
          )}
          <ReceiptTools receipt={receipt} />
        </ReportTitleButtons>
      </ReportTitle>
      <Text bold>{getDateText(receipt.get('createdTs'))}</Text>
      <Text muted bold>Kassör: {receipt.get('cashierName')}</Text>
      {customerDetails ? <Text muted bold>Kund: {customerDetails}</Text> : null}
      <PanelWrapper>
        <Panel>
          {receipt.get('items').sort(saleItemSort).map(item => renderReceiptItemsRow(item, receipt.get('cashierName')))}
          <Row>
            <SummaWrap>
              <Cell><Text bold>Summa</Text></Cell>
              <Cell pullRight>
                <Text bold>{CurrencyUtil.accountCurrency(receipt.get('amount'))}</Text>
              </Cell>
            </SummaWrap>
          </Row>
        </Panel>
        <div>
          <Text fs={15} pullLeftPadding bold>
            {isRefund ? 'Återbetalt' : 'Transaktioner'}{' '}
          </Text>
          <Panel>
            {salesTransactions && salesTransactions.map(({ paymentId, paymentMethod, paymentMethodName, transactionAmount }) => (
              renderTransactionsRow(paymentId, getPaymentMethodName(paymentMethodName || paymentMethod), CurrencyUtil.accountCurrency(transactionAmount))
            ))}
            {isRefund && refundTransactions.length > 0 && refundTransactions
              .filter(transaction => transaction.receiptId === receipt.get('receiptId'))
              .map(({ paymentId, paymentMethod, paymentMethodName, transactionAmount }) => (
                renderTransactionsRow(paymentId, getPaymentMethodName(paymentMethodName || paymentMethod), CurrencyUtil.accountCurrency(Math.abs(transactionAmount)))
              ))}
          </Panel>
          {!isRefund && refundTransactions.length > 0 && (
          <RefundStyle>
            {refundTransactions.map(({ receiptId, createdTs, transactionAmount }) => (
              <RefundItemWrap key={receiptId} onClick={() => handleSelectReceipt(receiptId)}>
                <Cell>
                  Återköpt {getDateText(createdTs)}
                </Cell>
                <Cell pullRight style={styleTextAmount}>{CurrencyUtil.accountCurrency(transactionAmount)}</Cell>
                <Cell><i className="far fa-chevron-right" /></Cell>
              </RefundItemWrap>
            ))}
          </RefundStyle>
          )}
        </div>
      </PanelWrapper>
      <CcuFooter>
        {receipt.get('ccuCode') && `Kontrollkod: ${receipt.get('ccuCode')}`}<br />
        {receipt.get('ccuId') && `Kassabeteckning: ${receipt.get('ccuId')}`}
      </CcuFooter>
    </>
  ) : null;
};

export default Receipt;
