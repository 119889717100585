import { colors } from '@Components/ui/styled/variables';

export const NEW_BOOKING = 'DRAGGER';
export const isBookingNew = ({ id }) => id === NEW_BOOKING;
export const isBookingCancelled = ({ attributes }) => attributes.status === 'Cancelled';
export const isSimpleBooking = ({ attributes }) => attributes.type === 'SimpleBooking';
export const isClassBooking = ({ attributes }) => attributes.type === 'ClassBooking';
export const isBookingReservation = ({ attributes }) => attributes.type === 'Reservation';
export const isScheduleException = ({ attributes }) => attributes.type === 'ScheduleException';

export const scheduleOpenTypes = {
  Normal: 'Normal',
  Overtime: 'Overtime'
};

export const scheduleTimeTypes = {
  Scheduled: 'Schemalagd tid',
  Journaled: 'Närvarotid',
  Overtime: 'Övertid',
  Qualifying: 'Karenstid',
  SickLeave80: 'Sjuk',
  SickChildLeave: 'VAB',
  Vacation: 'Semester',
  OnLeave: 'Tjänstledig',
  Furlough: 'Permission',
  WorkingHoursReduction: 'ATF',
  Absence: 'Frånvaro'
};

export function getDescription(description, services) {
  const hasServices = services && services.length > 0;
  const serviceDescriptions = hasServices ? services.map(s => s.name).join(', ') : '';
  return description || serviceDescriptions;
}

export function getSingleCustomer(props) {
  return props.type === 'SimpleBooking' && props.customers?.length === 1
    ? props.customers[0]
    : null;
}

export function getClassStatus(bookedSlots, maxSlots) {
  if (bookedSlots === maxSlots) {
    return {
      color: colors.danger,
      description: 'Fullbokad'
    };
  }
  if (bookedSlots / maxSlots >= 0.7) {
    return {
      color: colors.bgWarning,
      description: 'Fåtal platser kvar'
    };
  }
  return {
    color: colors.greenCircle,
    description: 'Platser kvar'
  };
}
