import moment from 'moment';
import { createSelector } from 'reselect';
import { calcWeekViewTimeOffsetForDay, scheduleBlockToMomentDates, viewDateToStartEnd } from '@Utils/time-util';
import { getRouteParams } from '@State/selectors';
import { getResourcesInView } from '@State/calendar-selectors';
import {
  calcGridScrollHeight, calculateGridPixelsPerRow, dayColumns, weekColumns
} from './grid-state-helper';

export const getColumnsSelector = createSelector(
  getRouteParams,
  getResourcesInView,
  (routeParams, resources) => {
    const { viewMode, viewDate, entityType, entityId } = routeParams;
    const resourceIds = resources.map(r => r.id);

    const columns = viewMode === 'week'
      ? weekColumns(viewDate, entityType, entityId, resourceIds)
      : dayColumns(viewDate, entityType, entityId, resourceIds);
    return columns;
  }
);

const getScrollbars = state => state.gridViewState.get('scrollBars');
const getScrollbarWidth = state => state.gridViewState.get('scrollBarWidth');
const getRowsPerHour = state => state.gridViewState.get('rowsPerHour');
const getGridClientWidth = state => state.gridViewState.get('gridClientWidth');
const getGridClientHeight = state => state.gridViewState.get('gridClientHeight');
const getGridSize = state => state.gridViewState.get('gridSize');
const getTopIndent = state => state.appState.get('embedTopIndent');
const getBottomIndent = state => state.appState.get('embedBottomIndent');
const getEmbedMode = state => state.appState.get('embedMode');

export const getGridProps = createSelector(
  getColumnsSelector,
  getRouteParams,
  getScrollbars,
  getScrollbarWidth,
  getRowsPerHour,
  getGridClientWidth,
  getGridClientHeight,
  getGridSize,
  getTopIndent,
  getBottomIndent,
  getEmbedMode,
  (columns, routeParams, scrollBars, scrollBarWidth, rowsPerHour, gridClientWidth, gridClientHeight, gridSize, embedTopIndent, embedBottomIndent, embedMode) => {
    // console.warn('RECALC getGridProps');
    const columnCount = columns.length;
    const chipIndentPixels = 3;
    const pixelsPerRow = calculateGridPixelsPerRow(rowsPerHour, gridSize);
    const gridScrollHeight = calcGridScrollHeight(pixelsPerRow, rowsPerHour);
    const viewDateStartEnd = viewDateToStartEnd(routeParams.viewMode, routeParams.viewDate);
    const columnWidth = columnCount === 0 ? gridClientWidth : gridClientWidth / columnCount;

    return {
      gridSize,
      pixelsPerRow,
      rowsPerHour,
      scrollBars,
      scrollBarWidth,
      gridClientWidth,
      gridClientHeight,
      gridScrollHeight,
      columnWidth,
      columnCount,
      chipIndentPixels,
      viewDateStartEnd,
      isWeekView: routeParams.viewMode === 'week',
      embedTopIndent,
      embedBottomIndent,
      embedMode
    };
  }
);

const getSchedules = (state, props) => state.schedulesByResource;

const emptyArray = [];
export const getScheduleBlockSelector = createSelector(
  getColumnsSelector,
  getSchedules,
  getGridProps,
  (columns, schedules, gridProps) => {
    const { pixelsPerRow, rowsPerHour, isWeekView } = gridProps;
    if (schedules === null || columns === null) {
      return emptyArray;
    }
    const columnWidthPct = 100 / columns.length;
    const hourHeight = pixelsPerRow * rowsPerHour;
    const scheduleBlocks = [];
    let columnIndex = 0;

    // This loop iterates over columns and scheduls to figure out which schedule blocks to render for the current view
    //
    columns.forEach((column) => {
      let blockIndex = 0;
      const combined = schedules.get('combined');
      const schedule = combined || schedules.get(column.resourceIds[0]);
      const blocks = combined ? combined.blocks : column.resourceIds
        .map(resId => schedules.get(resId)).filter(s => s)
        .reduce((b, s) => b.concat(s.blocks), []);

      if (schedule && blocks) {
        blocks.sort((a, b) => {
          const aSt = moment(`${a.day}T${a.start}`);
          const bSt = moment(`${b.day}T${b.start}`);
          return aSt.valueOf() - bSt.valueOf();
        }).forEach((block) => {
          if (block.day === column.date) {
            let pb = makePositionedBlock(block, hourHeight, columnIndex, columnWidthPct, isWeekView);
            const { resourceIds } = column;
            const {
              webOpen, vipOpen, closed, closedMax, publicHoliday, holidayName, openType, comment
            } = block;
            const { bookingMaxDaysInAdvance } = schedule;
            pb = Object.assign(pb, {
              resourceIds, webOpen, vipOpen, closedMax, closed, publicHoliday, holidayName, openType, comment, blockIndex, bookingMaxDaysInAdvance
            });
            scheduleBlocks.push(pb);
            blockIndex++;
          }
        });
      }

      columnIndex++;
    });
    return scheduleBlocks;
  }
);

function makePositionedBlock(block, hourHeight, columnIndex, columnWidthPct, isWeekView) {
  const blockMoments = scheduleBlockToMomentDates(block);

  const compTime = calcWeekViewTimeOffsetForDay(blockMoments.dayStart) * 1000;
  const minToPixelFactor = (hourHeight) / 60;
  const startPx = (((blockMoments.blStart - blockMoments.dayStart + compTime) / (1000 * 60)) * minToPixelFactor);
  const heightPx = ((blockMoments.blEnd - blockMoments.blStart) / (1000 * 60) * minToPixelFactor);

  return {
    leftPosPct: columnIndex * columnWidthPct,
    widthPct: columnWidthPct,
    topPx: startPx,
    heightPx,
    day: block.day
  };
}
