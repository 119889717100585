import React, { useState, useRef } from 'react';
import useOnClickOutside from '@Hooks/useOnClickOutside';
import DropDown from '@Components/ui/drop-down/dropdown';
import { TB } from '@Components/ui/styled/main';

const TBDropdown = ({
  children, label, icon, style, labelStyle, phoneMode,
  tabletMode, primary
}) => {
  const ref = useRef();
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  useOnClickOutside(ref, handleClose);

  return (
    <TB
      primary={primary}
      tabletMode={tabletMode}
      phoneMode={phoneMode}
      label={label}
      style={style}
      labelStyle={labelStyle}
      icon={icon}
      dropDown
      onClick={() => setOpen(!open)}
      ref={ref}
    >
      {open && children && (
        <DropDown light onClose={handleClose}>
          {children}
        </DropDown>
      )}
    </TB>
  );
};

export default TBDropdown;
