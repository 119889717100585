import React, { useCallback } from 'react';
import Header, { Toolbar } from '@Components/nav/header';
import { getSectionUrl, navigate } from '@Utils/navigate';

const onPrint = (ev) => {
  ev.preventDefault();
  ev.target.blur();
  window.print();
};

const StaffHeader = ({ match }) => {
  const routeParams = match.params;
  const showReport = routeParams.id === 'report';

  const onShowUsers = useCallback(() => {
    navigate(getSectionUrl(routeParams, 'staff'));
  }, []);
  const onShowReport = useCallback(() => {
    navigate(getSectionUrl(routeParams, 'staff', 'report'));
  }, []);

  return (
    <Header
      title="Personalliggare"
      className="admin-header staff-journal-header"
      onBackButtonClick={onShowUsers}
      showBackButton={showReport}
      routeParams={routeParams}
    >

      <Toolbar>
        {showReport
          ? <button type="button" className="btn btn-cta-secondary" onClick={onPrint}>Skriv ut</button>
          : <button type="button" className="btn btn-cta" onClick={onShowReport}>Visa journal</button>}

      </Toolbar>
    </Header>
  );
};

export default StaffHeader;
