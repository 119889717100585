import styled, { css } from 'styled-components';
import {
  colors, fs, fsw
} from '@Components/ui/styled/variables';

export default styled.div`
  position: relative;
  border-radius: 8px;
  background-color: ${p => p.bgColor ? p.bgColor : colors.grayLightest};
  padding: 16px 18px;
  margin-bottom: 12px;

  ${p => p.minHeight && css`
    min-height: ${p.minHeight};
  `};
  ${p => p.maxWidth && css`
    max-width: ${p.maxWidth};
  `};

  .alert:last-child,
  .form-group:last-child {
    margin-bottom: 2px;
  }
  p:last-child {
    margin-bottom: 0;
  }

  h4, h5 {
    margin: 0 0 10px 0;
  }
  pre {
    background: #fff;
  }
  hr {
    margin: 6px 0;
  }
  .btn-default {
    background: #fff;
  }

  @media print {
    padding: 0;
  }
  cursor: ${p => p.onClick ? 'pointer' : 'default'};

  ${p => p.disabled && css`
    opacity: 0.5;
    pointer-events: none;
    user-select: none;
  `};
`;

export const PanelDefault = styled.div`
  margin-bottom: 17px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

export const PanelHeading = styled.div`
  padding: 15px;
  background-color: ${colors.grayLightest};
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid #ddd;
`;
export const PanelBody = styled.div`
  padding: 15px;
  font-weight: ${fsw.medium};
`;
