import axios from 'axios';
import { axiosDefault, axiosErrorHandler, axiosFormData, checkStatusAxios } from '@Utils/ajax-util';
import { prefixApiUrl } from '@Utils/url-util';

const getBokadirektId = (url) => url.substring(url.lastIndexOf('-') + 1);

export const importBokadirektReviews = (orgCtxName, ctxName, bokadirektUrl) => {
  return async (dispatch) => {
    const url = prefixApiUrl(`/powertools/import/bokadirekt/reviews/${orgCtxName}/${ctxName}/`);
    const config = axiosDefault();

    try {
      const response = await axios.post(url, {
        url: bokadirektUrl,
        bokaDirektId: getBokadirektId(bokadirektUrl)
      }, config);
      dispatch(checkStatusAxios(response));
      return response.data;
    } catch (error) {
      axiosErrorHandler(error, dispatch);
      throw error;
    }
  };
};

export const customerImportUrl = {
  generalCsv: 'csvcustomers/customers',
  bokadirektV1: 'bokadirekt/customers',
  bokadirektV2: 'bokadirekt/v2/customers'
};

export const importCsvCustomers = (orgCtxName, ctxName, params) => {
  return async (dispatch) => {
    const { importUrl, file, resourceId } = params;
    const url = prefixApiUrl(`/powertools/import/${importUrl}/${orgCtxName}/${ctxName}/`);
    const config = axiosFormData();

    const formData = new FormData();
    formData.append('file', file);

    if (resourceId && resourceId !== '0') {
      formData.append('associateWithResourceId', resourceId);
    }

    try {
      const response = await axios.post(url, formData, config);
      dispatch(checkStatusAxios(response));
      return response.data;
    } catch (error) {
      axiosErrorHandler(error, dispatch);
      throw error;
    }
  };
};

export const importCsvArticles = (orgCtxName, ctxName, file) => {
  return async (dispatch) => {
    const url = prefixApiUrl(`/locations/${orgCtxName}/${ctxName}/inventory/import-items-csv/`);
    const config = axiosFormData();

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post(url, formData, config);
      dispatch(checkStatusAxios(response));
      return response.data;
    } catch (error) {
      axiosErrorHandler(error, dispatch);
      throw error;
    }
  };
};
