import moment from 'moment';
import queryString from 'query-string';
import { createSelector } from 'reselect';
import uniqBy from 'lodash/uniqBy';
import { formatOrgNo } from '@Utils/luhn-util';
import { reports } from '@Utils/preference-keys';
import { reportingFormat, reportingCount } from '@Utils/format';
import { getFeatures, getWebPaymentEnabled, getRouteParams, getLocationName } from '@State/selectors';
import { DATE_PICKER_FORMAT } from '@Utils/time-constants';
import msg from '@Components/reports/data-export/data-export.msg';
import { txt } from '@Utils/i18n-util';

export const ReportingDate = {
  Start: 'Start',
  Created: 'Created'
};

export const ReportingType = {
  Top: 'Top',
  New: 'New'
};

export function getDateTypeDescription(dateType) {
  switch (dateType) {
    case ReportingDate.Start:
      return 'Tidpunkt för bokningen';
    case ReportingDate.Created:
      return 'Då bokingen skapades';
  }
}

const bookedFilter = service => service.status === 'Booked';
const showFilter = service => service.status === 'Show';
const noShowFilter = service => service.status === 'NoShow';
const cancelledFilter = service => service.status === 'Cancelled';
const revenueFilter = service => service.status === 'Booked' || service.status === 'Show';
const noRevenueFilter = service => service.status === 'NoShow' || service.status === 'Cancelled';
const negate = value => value > 0 ? value * -1 : value;

export function getBookingReportColumns(bookings = []) {
  return {
    totalCount: reportingCount(getTotal(bookings, 'count')),
    totalRevenue: reportingFormat(getTotal(bookings, 'revenue')),
    bookedCount: reportingCount(getTotal(bookings.filter(bookedFilter), 'count')),
    showCount: reportingCount(getTotal(bookings.filter(showFilter), 'count')),
    noShowCount: reportingCount(negate(getTotal(bookings.filter(noShowFilter), 'count'))),
    cancelledCount: reportingCount(negate(getTotal(bookings.filter(cancelledFilter), 'count'))),
    revenue: reportingFormat(getTotal(bookings.filter(revenueFilter), 'revenue')),
    noRevenue: reportingFormat(negate(getTotal(bookings.filter(noRevenueFilter), 'revenue')))
  };
}

export function getTotal(items, key) {
  if (key) {
    return items.reduce((sum, item) => sum + parseFloat(item[key] || 0), 0);
  }
  return Object.keys(items).reduce((sum, item) => sum + parseFloat(items[item] || 0), 0);
}

export const getAvailableReports = createSelector(
  getFeatures,
  getWebPaymentEnabled,
  (features, paymentEnabled) => {
    const reports = [{
      id: 'bookings',
      name: 'Bokningsrapport',
      group: 'Bokning',
      icon: 'fa-chart-pie',
      showResources: true,
      showGroups: true,
      showAll: true,
      showDateType: true,
      showPrint: true
    }, {
      id: 'booking-list',
      name: 'Bokningslista',
      group: 'Bokning',
      icon: 'fa-calendar-alt',
      showResources: true,
      showAll: true,
      showPrint: true
    }, {
      id: 'sms',
      name: 'SMS-rapport',
      group: 'Bokning',
      icon: 'fa-comment-dots',
      showPrint: true
    }];

    if (features.EnablePOS || paymentEnabled) {
      reports.push({
        id: 'sales',
        name: 'Försäljning',
        group: 'Kassa',
        icon: 'fa-chart-line',
        showPosOrgs: true,
        showPosUsers: true,
        showPrint: true
      });
      if (features.EnablePrepaidBookings) {
        reports.push({
          id: 'pre-payments',
          name: 'Förbetalda bokningar',
          group: 'Kassa',
          icon: 'fa-money-bill-transfer',
          showPrint: true
        });
      }

      if (features.EnableDeferredSales) {
        reports.push({
          id: 'deferred-sales',
          name: 'Bokförd försäljning',
          group: 'Kassa',
          icon: 'fa-calendar-check',
          showPrint: true
        });
      }
    }
    if (features.EnablePOS) {
      reports.push({
        id: 'stock',
        name: 'Lager',
        group: 'Kassa',
        icon: 'fa-box-open',
        hideFilter: true,
        showPrint: true
      });
      reports.push({
        id: 'gift-cards',
        name: 'Presentkort',
        group: 'Kassa',
        icon: 'fa-gift',
        tabs: [{
          navId: 'ledger',
          name: 'Reskontra',
          singleDate: true
        }, {
          navId: 'redeemed',
          name: 'Inlösta'
        }],
        showPosOrgs: true,
        showPrint: true
      });
    }

    reports.push({
      id: 'staff',
      name: 'Löneunderlag',
      group: 'Personal',
      icon: 'fa-user-clock'
    });

    reports.push({
      id: 'booking-export',
      name: 'Exportera bokningar',
      group: 'Dataexport',
      icon: 'fa-file-export',
      hideFilter: true
    });

    reports.push({
      id: 'customer-export',
      name: 'Exportera kunder',
      group: 'Dataexport',
      icon: 'fa-file-export',
      hideFilter: true
    });

    return reports;
  }
);

export const defaultBookingListColumns = createSelector(
  getFeatures,
  ({ EnableVehicleBooking, EnableCompanyBooking }) => {
    return [
      { name: 'Tid', checked: true, id: 'isShowDate' },
      { name: 'Tjänst', checked: true, id: 'isShowService' },
      EnableVehicleBooking ? { name: 'Fordon', checked: true, id: 'isShowVehicleRegNo' } : null,
      { name: 'Pris', checked: true, id: 'isShowAward' },
      EnableCompanyBooking ? { name: 'Företag', checked: true, id: 'isShowBusiness' } : null,
      { name: EnableCompanyBooking ? 'Kontaktperson' : 'Kund', checked: true, id: 'isShowCustomer' },
      { name: 'Telefon', checked: true, id: 'isShowPhone' },
      { name: 'Källa', checked: true, id: 'isShowSource' },
      { name: 'Status', checked: true, id: 'isShowStatus' },
      { name: 'Övrigt', checked: true, id: 'isShowOther' },
      { name: 'Egna fält', checked: true, id: 'isShowCustomFields' }
    ].filter(item => item);
  }
);

export const bookingColumnsSelector = createSelector(
  defaultBookingListColumns,
  state => state.userClientPreferences.getIn(['user', reports.columnSettings]),
  (defaultColumns, columnSettings) => {
    return defaultColumns.map(column => {
      const setting = columnSettings && columnSettings.find(s => s.get('id') == column.id);
      if (setting) {
        column.checked = setting.get('checked');
      }
      return column;
    });
  }
);

export const getUserClientPreferences = createSelector(
  state => state.userClientPreferences.get('user'),
  (prefs) => prefs?.toJS() || []
);

export const reportsQuerySelector = createSelector(
  state => state.resourcesById,
  state => state.orderedGroups,
  state => state.posOrgs,
  state => state.cashiersById,
  (state, props) => props.location.search,
  (resourcesById, orderedGroups, posOrgs, cashiersById, search) => {
    const {
      resourceId, groupId, posOrgId, userId, startDate, endDate, dateType
    } = queryString.parse(search);
    const group = orderedGroups.find(g => g.get('id') === parseInt(groupId));
    const resource = resourcesById.get(parseInt(resourceId));
    const user = cashiersById.get(parseInt(userId));
    const posOrg = posOrgs.find(o => o.get('id') === parseInt(posOrgId));
    const defaultPosOrg = posOrgs && posOrgs.first();
    const startDateMoment = startDate ? moment(startDate) : moment().startOf('month');
    const endDateMoment = endDate ? moment(endDate) : moment().endOf('month');

    return {
      group: group ? group.toJS() : null,
      resource,
      posOrg: posOrg || defaultPosOrg,
      user,
      startDate: startDateMoment,
      endDate: endDateMoment,
      dateType: dateType || ReportingDate.Start
    };
  }
);

export const getReportParams = createSelector(
  reportsQuerySelector,
  ({ posOrg, user, startDate, endDate, dateType }) => {
    return {
      posOrgId: posOrg?.get('id'),
      start: moment(startDate).format('YYYY-MM-DD'),
      end: moment(endDate).format('YYYY-MM-DD'),
      userId: user?.get('id'),
      dateType
    };
  }
);

export function channelText(channel) {
  switch (channel) {
    case 'Cal':
      return 'Kalender';
    case 'Web':
      return 'Webb';
    case 'WebVacci':
      return 'Vacci';
    default:
      return '';
  }
}

export function statusText(status) {
  switch (status) {
    case 'Show':
      return 'Show';
    case 'NoShow':
      return 'No show';
    case 'Booked':
      return 'Bokad';
    case 'Cancelled':
      return 'Avbokad';
    default:
      return '';
  }
}

export function flagsText(key) {
  switch (key) {
    case 'askedForPerson':
      return 'Bokat person';
    case 'dropIn':
      return 'Drop in';
    default:
      return '';
  }
}

function getContextName(group, resource, locationName) {
  if (resource) {
    return resource.name;
  }
  if (group) {
    return group.name;
  }
  return locationName;
}

export function getDateText(startDate, endDate) {
  const dayFormat = 'D';
  const monthFormat = 'D MMMM';
  const fullFormat = 'D MMMM YYYY';

  if (startDate.isSame(endDate, 'day')) {
    return `${startDate.format(fullFormat)}`;
  }
  if (startDate.isSame(endDate, 'month')) {
    return `${startDate.format(dayFormat)}-${endDate.format(fullFormat)}`;
  }
  if (startDate.isSame(endDate, 'year')) {
    return `${startDate.format(monthFormat)}-${endDate.format(fullFormat)}`;
  }
  return `${startDate.format(fullFormat)}-${endDate.format(fullFormat)}`;
}

export const getCurrentReport = createSelector(
  getRouteParams,
  getAvailableReports,
  (routeParams, availableReports) => {
    return availableReports.find(report => report.id === routeParams.subsection);
  }
);

export const getReportDateText = createSelector(
  getCurrentReport,
  reportsQuerySelector,
  (state, props) => props.match.params.id,
  (currentReport, { startDate, endDate }, id) => {
    const currentTab = currentReport?.tabs?.find(t => t.navId === id || !t.navId && !id);
    const report = { ...currentReport, ...currentTab };
    const { hideFilter, singleDate } = report;

    return hideFilter
      ? getDateText(moment(), moment())
      : getDateText(startDate, singleDate ? startDate : endDate);
  }
);

export const getReportTitle = createSelector(
  getCurrentReport,
  reportsQuerySelector,
  (currentReport, reportsQuery) => {
    const { user } = reportsQuery;

    if (currentReport?.id === 'sales' && user) {
      return `${currentReport.name} (${user.get('name')})`;
    }
    return currentReport?.name;
  }
);

export const getReportContext = createSelector(
  getCurrentReport,
  getLocationName,
  reportsQuerySelector,
  (currentReport, locationName, reportsQuery) => {
    const { resource, group, posOrg } = reportsQuery;

    if (currentReport?.showPosOrgs && posOrg) {
      return `${posOrg.get('companyName')}, ${formatOrgNo(posOrg.get('companyOrgNo'))}`;
    }
    return currentReport?.showResources
      ? getContextName(group, resource, locationName)
      : null;
  }
);

export const getDataExportFilter = createSelector(
  state => state.reportsData,
  (reportsData) => {
    const {
      resourceIds,
      serviceIds,
      locationIds,
      fields,
      customFields,
      dateFilter,
      start,
      end,
      ...rest
    } = reportsData?.get('values');
    return {
      locationIds: locationIds ?? [],
      resourceIds: resourceIds ?? [],
      serviceIds: serviceIds ?? [],
      fields: fields ?? [],
      customFields: customFields ?? [],
      dateFilter: dateFilter ?? ReportingDate.Start,
      type: rest.type ?? ReportingType.New,
      limit: rest.limit ?? 20,
      start: start ?? moment().startOf('month').format(DATE_PICKER_FORMAT),
      end: end ?? moment().endOf('month').format(DATE_PICKER_FORMAT),
      ...rest
    };
  }
);

export const getDataExportList = createSelector(
  state => state.reportsData.get('reportOptions'),
  getDataExportFilter,
  (data = {}, { locationIds }) => {
    const filter = (arr = []) => !locationIds.length ? arr
      : arr.filter(item => item.locationId ? locationIds.includes(item.locationId) : true);
    return {
      customFieldsList: uniqBy(filter(data.customFields), 'field'),
      locationsList: data.locations ?? [],
      resourceGroupsList: filter(data.resourceGroups),
      resourcesList: filter(data.resources),
      serviceGroupsList: filter(data.serviceGroups),
      servicesList: filter(data.services)
    };
  }
);

export const getDataExportPayload = createSelector(
  getDataExportFilter,
  ({ dateFilter, serviceIds, fields, customFields, resourceIds, start, end }) => {
    return {
      start: moment(start).format('YYYY-MM-DD'),
      end: moment(end).format('YYYY-MM-DD'),
      dateFilter,
      serviceIds,
      resourceIds,
      customFields: uniqBy(customFields?.map(field => ({ field, header: field })) ?? [], 'field'),
      fields: fields?.map(field => ({ field, header: msg[field] ? txt(msg[field]) : field })) ?? []
    };
  }
);

export const getCustomersExportPayload = createSelector(
  getDataExportFilter,
  ({ start, end, serviceIds, resourceIds, type = ReportingType.New, csv = false }) => {
    return {
      start: moment(start).format('YYYY-MM-DD'),
      end: moment(end).format('YYYY-MM-DD'),
      type,
      limit: type === ReportingType.New ? null : 500,
      serviceIds,
      resourceIds,
      csv
    };
  }
);

export function sortDataExportGroups(arr) {
  return [
    ...arr.filter(gr => gr.groupId > 0).sort((a, b) => a.name.localeCompare(b.name)),
    ...arr.filter(gr => gr.groupId < 0).sort((a, b) => a.name.localeCompare(b.name))
  ];
}

export function getDefaultDateLabel(title, startDate) {
  const date = moment(startDate).format('ll');
  return `${title} ${date}`;
}

export function getStaffReportDefaultLabel(startDate) {
  const date = moment(startDate).format('MMMM YYYY');
  return `Löneunderlag ${date}`;
}

export function getStockReportDefaultLabel() {
  const date = moment().format('ll');
  return `Lagerrapport ${date}`;
}

export function minutesToHours(minutes) {
  if (!minutes) {
    return '';
  }
  const hour = Math.floor(minutes / 60);
  const minute = String(minutes % 60).padStart(2, '0');
  return `${hour}:${minute}`;
}

export function hoursToMinutes(hours) {
  const [hour, minute] = String(hours).split(':');
  return (parseInt(hour || '0') * 60) + parseInt(minute || '0');
}
