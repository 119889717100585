import React from 'react';
import styled from 'styled-components';
import { colors } from '@Components/ui/styled/variables';
import RingLoader from 'halogenium/lib/RingLoader';
import ClipLoader from 'halogenium/lib/ClipLoader';

const LoaderWrapper = styled.div`
  text-align: center;
  position: relative;
  top: ${p => p.topValue};
`;

const Loader = ({ topValue = '50px', topPosition = false, solid = false }) => {
  if (topPosition) {
    return (
      <LoaderWrapper topValue={topValue}>
        <ClipLoader color="#dedede" />
      </LoaderWrapper>
    );
  }

  return (
    <div className={solid ? 'loader-container solid' : 'loader-container'}>
      {solid ? <img src="/c.svg" alt="" /> : <ClipLoader color="#dedede" />}
    </div>
  );
};

export default Loader;
