import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getTimeString } from '@Utils/time-util';
import { saveUserEmployment } from '@State/users-actions';
import { getEmploymentTypeDescription } from '@Utils/users';
import { Panel, Button, FormGroup } from '@Components/ui/styled/main';
import StaffEmploymentModal from './staff-employment-modal';

const StaffEmployment = ({ user }) => {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);

  const onSaveEmployment = (values) => {
    return dispatch(saveUserEmployment(user.id, values))
      .finally(() => setShowModal(false));
  };

  const {
    employmentType, workMinutesPerDay, workDaysPerWeek, lunchMinutesPerDay
  } = user?.employment || {};

  return (
    <Panel>
      <div className="form-group">
        <p className="mb0">
          <label className="control-label">Anställning</label>
        </p>
        <div className="text-muted">
          Inställningar för beräkning av tid i löneunderlag
        </div>
      </div>
      <div className="row mb3">
        <div className="col-xs-3">
          <FormGroup
            labelText="Anst.form"
            text={getEmploymentTypeDescription(employmentType)}
          />
        </div>
        <div className="col-xs-3">
          <FormGroup
            labelText="Timmar"
            text={getTimeString(workMinutesPerDay * workDaysPerWeek)}
          />
        </div>
        <div className="col-xs-3">
          <FormGroup
            labelText="Dagar"
            text={workDaysPerWeek}
          />
        </div>
        <div className="col-xs-3">
          <FormGroup
            labelText="Lunch"
            text={getTimeString(lunchMinutesPerDay)}
          />
        </div>
      </div>
      <Button small white onClick={() => setShowModal(true)}>Ändra anställning</Button>
      {showModal && (
        <StaffEmploymentModal
          user={user}
          onSubmit={onSaveEmployment}
          onClose={() => setShowModal(false)}
        />
      )}
    </Panel>
  );
};

export default StaffEmployment;
