import React, { useState } from 'react';
import CurrencyUtil from '@Utils/currency-util';
import ModalDialog from '@Components/dialogs/modal-dialog';
import { DialogCenterRowWrap } from '@Components/dialogs/dialog-styles';
import Text from '@Components/ui/styled/text-element';
import SetAmountModal from '@Components/pos/payment/set-amount-modal';

const ExternalPayment = ({
  totalAmount, onSubmit, onClose, title, text, isRefund, submitText, disableChangeAmount
}) => {
  const [amount, setAmount] = useState(totalAmount);
  const [showAmount, setShowAmount] = useState(false);

  const handleSetAmount = (newAmount) => {
    setAmount(newAmount);
    setTimeout(() => setShowAmount(false), 100);
  };

  if (showAmount) {
    return (
      <SetAmountModal
        totalAmount={totalAmount}
        onSubmit={handleSetAmount}
        onClose={() => setShowAmount(false)}
      />
    );
  }

  return (
    <ModalDialog
      title={title}
      onClose={onClose}
      closeOnClickOutside={false}
      onEnter={() => onSubmit(amount)}
      buttons={[{
        name: submitText || (isRefund ? 'Återbetalning klar' : 'Betalning mottagen'),
        primary: true,
        onClick: () => onSubmit(amount)
      }, !isRefund && !disableChangeAmount && {
        name: 'Dela upp betalning',
        gray: true,
        onClick: () => setShowAmount(true)
      }]}
    >
      <DialogCenterRowWrap>
        <Text marginBottom fs={30}>{CurrencyUtil.accountCurrency(amount, 2)}</Text>
        <Text muted>{text}</Text>
      </DialogCenterRowWrap>
    </ModalDialog>
  );
};

export default ExternalPayment;
