import React, { Component } from 'react';
import ModalDialog from '@Components/dialogs/modal-dialog';
import CurrencyUtil from '@Utils/currency-util';
import {
  maxLength, convertToFloat, isValidInput, convertToString
} from '@Utils/numpad-util';
import Numpad from '@Components/inputs/numpad';
import CalcDisplay from '@Components/ui/calc-display';

class SetAmountModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      amount: '',
      isValid: true
    };
  }

  componentDidMount() {
    this.handleNumpadUpdate(this.state.amount);
  }

  handleNumpadUpdate = value => {
    if (isValidInput(value, maxLength)) {
      const { totalAmount } = this.props;
      const isValid = value > 0 && convertToFloat(value) <= totalAmount;
      this.setState({ amount: value, isValid });
    }
  };

  onSubmit = () => {
    return this.props.onSubmit(convertToFloat(this.state.amount));
  };

  render() {
    const { totalAmount, onClose } = this.props;
    const { isValid, amount } = this.state;
    const TotalAmount = CurrencyUtil.accountCurrency(totalAmount, 2);
    const AmountReceived = CurrencyUtil.accountCurrency(convertToFloat(this.state.amount), 2);
    const amountError = !isValid && amount > 0;

    return (
      <ModalDialog
        title={`Att betala ${TotalAmount}`}
        onClose={onClose}
        closeOnClickOutside={false}
        buttons={[
          {
            name: 'OK',
            primary: true,
            onClick: this.onSubmit,
            disabled: !isValid
          }
        ]}
      >
        <CalcDisplay
          large
          align="center"
          label={amountError ? 'Belopp för stort' : 'Ange del av belopp'}
          labelPosition="below"
          labelError={amountError}
        >
          {AmountReceived}
        </CalcDisplay>
        <Numpad
          onChange={this.handleNumpadUpdate}
          onEnter={isValid ? this.onSubmit : null}
          input={this.state.amount}
        />
      </ModalDialog>
    );
  }
}

export default SetAmountModal;
