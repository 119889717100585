import moment from 'moment';
import queryString from 'query-string';
import { apiUrl, bookingApiUrl } from './config';
import { getSessionOrgLoc } from './session';
import { viewDateFromDate } from './time-util';

function prefixWithOLSlug(url, orgUrl, icalUrl, v2Url) {
  const { org, loc } = getSessionOrgLoc();

  if (orgUrl) {
    return `/organisations/${org}${url}`;
  }
  if (icalUrl) {
    return `/icalendar/${org}/${loc}${url}`;
  }
  if (v2Url) {
    return `/v2/locations/${org}/${loc}${url}`;
  }
  return `/locations/${org}/${loc}${url}`;
}

function shouldPrefixUrl(url) {
  const excludedUrls = [
    '/sysadmin',
    '/invoices',
    '/pwreset/',
    '/account/',
    '/organisations/',
    '/receipt-preview/',
    '/fortnox/refresh-tokens',
    '/fortnox/auth/system',
    '/auth/user-session',
    '/auth/locations/',
    '/signup',
    '/oauth/token',
    '/powertools/',
    '/partners/',
    '/user/'
  ];
  for (const index in excludedUrls) {
    if (url.startsWith(excludedUrls[index])) {
      return false;
    }
  }
  return true;
}

export function prefixApiUrl(url) {
  return `${apiUrl()}/api${url}`;
}

export function prefixBookingUrl(url) {
  return `${bookingApiUrl()}/api${url}`;
}

export function prefixAjaxUrl(url, org, ical, v2) {
  if (shouldPrefixUrl(url)) {
    url = prefixWithOLSlug(url, org, ical, v2);
  }
  return prefixApiUrl(url);
}

export const translateViewDate = (viewMode, viewDate) => {
  return viewDate === 'today'
    ? viewDateFromDate(viewMode, moment())
    : viewDate;
};

export const getBookingIdFromHash = (hash) => {
  return hash
    ? parseInt(hash.replace(/[^0-9]/, ''))
    : null;
};

export const getResourceIdsFromSearch = (search) => {
  const { r } = queryString.parse(search);
  if (typeof r === 'string') {
    return r.split(',').map(id => parseInt(id));
  }
  return [];
};

export const getSearchFromResourceIds = (resourceIds) => {
  return `?r=${resourceIds.join(',')}`;
};
