import styled from 'styled-components';
import { colors, fs } from '@Components/ui/styled/variables';

export const Title = styled.h5`
  font-size: ${fs.s};
  margin: 12px 0 8px 0;
  color: ${colors.textMuted};
`;

export const Container = styled.section`
  padding: 16px 0;
  height: 100%;
  margin: 20px 0;
`;

export const AccountsFilterStyled = styled.div`
  padding: 2px 10px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  opacity: ${p => p.notInUse ? 0.3 : 1};

  > div, .radio {
    margin: 0;
    padding-right: 6px;

    input[type="radio"] {
      margin-left: -18px;
    }

    label {
      font-size: 13px;
      margin: 0;
      padding-left: 22px;
      font-weight: 600;
    }
  }
  .sorted {
    cursor: pointer;
    display: flex;
    i {
      color: #C8C8C8;
      padding-left: 10px;
      display: flex;
      align-items: center;
    }
  }
`;
