import { List, fromJS } from 'immutable';
import moment from 'moment';

import {
  STAFF_PNO_UPDATED,
  STAFF_ENTRY_CREATED,
  STAFF_LIST_FETCHED,
  STAFF_USER_ENTRIES_FETCHED,
  STAFF_ENTRY_DELETED,
  STAFF_ENTRY_UPDATED,
  STAFF_ENTRY_REPORT_FETCHED,
  sortStaffEntries
} from './staff-actions';

export function staffState(state = List(), action = null) {
  switch (action.type) {
    case STAFF_LIST_FETCHED: {
      return fromJS(action.entries);
    }
    case STAFF_PNO_UPDATED: {
      const index = state.findIndex((item) => item.get('id') === action.locUserId);
      return state.setIn([index, 'pno'], action.pno);
    }
    default:
      return state;
  }
}

export function staffReportState(state = List(), action = null) {
  switch (action.type) {
    case STAFF_ENTRY_REPORT_FETCHED:
      return fromJS(action.entries)
        .sort((a, b) => sortStaffEntries(a.toObject(), b.toObject()))
        .groupBy(e => moment(e.get('startTime')).startOf('month'))
        .map(month => month.groupBy(e => e.get('userId')));

    default:
      return state;
  }
}

export function staffUserEntriesState(state = List(), action = null) {
  switch (action.type) {
    case STAFF_USER_ENTRIES_FETCHED: {
      return fromJS(action.entries);
    }
    case STAFF_ENTRY_CREATED: {
      return state.push(action.data);
    }
    case STAFF_ENTRY_UPDATED: {
      const index = state.findIndex(entry => entry.get('id') === action.data.entryId);
      if (index !== -1) {
        return state.set(index, state.get(index).merge({ startTime: action.data.checkinTime, endTime: action.data.checkoutTime }));
      }
      return state;
    }
    case STAFF_ENTRY_DELETED: {
      const index = state.findIndex(entry => entry.get('id') === action.entryId);
      if (index !== -1) {
        return state.set(index, state.get(index).merge({ deleted: true }));
      }
      return state;
    }
    default:
      return state;
  }
}
