import React, { Component } from 'react';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { pos } from '@Utils/preference-keys';
import SubmitButton from '@Components/ui/submit-button';

class PosConfigForm extends Component {
  render() {
    const { handleSubmit, useOperatorIdleTimeout } = this.props;

    return (
      <form autoComplete="off" className="form-horizontal" onSubmit={handleSubmit}>

        <div className="row">
          <div className="col-sm-offset-3 col-sm-9">
            <h3>Inställningar för kassa</h3>
            <p className="text-muted">
              Inställningar för samtliga kassor och användare på detta konto
            </p>
            <br />
          </div>
        </div>

        <div className="form-group">
          <label className="col-sm-3 control-label">Inloggning</label>
          <div className="col-sm-9">
            <div className="checkbox">
              <label>
                <Field name={pos.useOperatorPinCode} component="input" type="checkbox" />
                Använd PIN-kod för att logga in i kassan
              </label>
            </div>
          </div>
        </div>

        <div className="form-group">
          <label className="col-sm-3 control-label">Utloggning</label>
          <div className="col-sm-9">
            <div className="checkbox">
              <label>
                <Field name={pos.logoutOnNavigate} component="input" type="checkbox" />
                Lås kassan vid navigering till annan sida
              </label>
            </div>
          </div>
        </div>

        <div className="form-group">
          <label className="col-sm-3 control-label" />
          <div className="col-sm-9">
            <div className="checkbox">
              <label>
                <Field name={pos.logoutAfterPurchase} component="input" type="checkbox" />
                Lås kassan efter varje köp
              </label>
            </div>
          </div>
        </div>

        <div className="form-group">
          <label className="col-sm-3 control-label" />
          <div className="col-sm-9">
            <div className="checkbox">
              <label>
                <Field name={pos.useOperatorIdleTimeout} component="input" type="checkbox" />
                Lås kassan vid inaktivitet
              </label>
            </div>
          </div>
        </div>

        {useOperatorIdleTimeout && (
          <div className="form-group">
            <label className="col-sm-3 control-label">Tid innan låsning</label>
            <div className="col-sm-9">
              <Field
                name={pos.operatorIdleTimeout}
                component="input"
                type="number"
                className="form-control inline-sm"
                required
                min={1}
                max={86400}
              />
              <p className="form-control-static inline-md">sekunder</p>
            </div>
          </div>
        )}

        <hr />

        <div className="form-group">
          <label className="col-sm-3 control-label">Funktioner</label>
          <div className="col-sm-9">
            <div className="checkbox">
              <label>
                <Field name={pos.lockVunitToDevice} component="input" type="checkbox" />
                Koppla kassa till en enhet vid öppning
              </label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-offset-3 col-sm-5">
            <p className="text-muted">
              Kassan kommer att vara kopplad till enheten den öppnas på,
              och kan inte användas på andra enheter till den stängs.
            </p>
          </div>
        </div>

        <hr />

        <div className="form-group">
          <label className="col-sm-3 control-label">Standard momssats</label>
          <div className="col-sm-4">
            <Field
              name={pos.defaultVatPct}
              component="input"
              type="number"
              className="form-control inline-sm"
              parse={val => val && parseInt(val)}
              min={0}
              max={100}
            />
            <p className="form-control-static inline-md">%</p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-offset-3 col-sm-5">
            <p className="text-muted">
              Används vid betalning av en bokning som inte har en tjänst
            </p>
          </div>
        </div>

        <hr />

        <div className="form-group">
          <div className="col-sm-offset-3 col-sm-9">
            <SubmitButton {...this.props} />
          </div>
        </div>
      </form>
    );
  }
}

const selector = formValueSelector('pos-config');

const mapStateToProps = (state) => {
  return {
    useOperatorIdleTimeout: selector(state, pos.useOperatorIdleTimeout)
  };
};

export default reduxForm({
  form: 'pos-config',
  enableReinitialize: true
})(connect(mapStateToProps)(PosConfigForm));
