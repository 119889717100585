import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { storage } from '@Utils/preference-keys';
import SubmitButton from '@Components/ui/submit-button';
import ProgressButton from '@Components/ui/progress-button';
import { createValidator, required, timePeriod } from '@Utils/validation';
import { getSectionUrl } from '@Utils/navigate';
import PeriodInput from '../../inputs/period-input';

class StorageConfigForm extends Component {
  render() {
    const {
      routeParams, handleSubmit, pristine, autoPurgeEnabled, storageSettingsAccepted, purgeLogsEnabled,
      isSysAdmin, restoreAutoDeleted
    } = this.props;
    const contactsUrl = getSectionUrl(routeParams, 'admin', 'contacts');

    return (
      <form autoComplete="off" className="form-horizontal" onSubmit={handleSubmit}>

        <div className="row">
          <div className="col-sm-offset-3 col-sm-9">
            <h3>Inställningar för personuppgifter</h3>
          </div>
        </div>

        <div className="form-group">
          <div className="col-sm-offset-3 col-sm-9">
            <div className="checkbox">
              <label>
                <Field name={storage.autoPurgeEnabled} component="input" type="checkbox" />
                <strong>Aktivera automatisk gallring av personuppgifter</strong>
              </label>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-offset-3 col-sm-6">
            <p className="text-muted">
              För att uppfylla kraven i dataskyddsförordningen (GDPR) får personuppgifter inte sparas längre än nödvändigt.
              Personuppgifter måste gallras bort efter en viss tid om det inte längre finns nån laglig grund för lagringen.
            </p>
            <p className="text-muted">
              Observera att vårdgivare inom hälso- och sjukvården regleras av bestämmelser i patientdatalagen som har andra krav på lagring än GDPR.
            </p>
            <p className="text-muted">
              Läs mer om GDPR och gällande villkor under <Link to={contactsUrl}>Kontakter & villkor</Link>
              .
            </p>
          </div>
        </div>
        <br /> <br />

        {autoPurgeEnabled && (
          <div>
            <Field
              name={storage.bookingMaxAge}
              label="Radera bokningar"
              labelAfter="efter att tiden för bokningen har passerats"
              description="Bokningar visas i kalendern och används även för kundhistorik och rapporter"
              component={PeriodInput}
            />
            <br />
            <div className="form-group">
              <div className="col-sm-offset-3 col-sm-9">
                <div className="checkbox">
                  <label>
                    <Field name={storage.purgeLogsEnabled} component="input" type="checkbox" />
                    Aktivera gallring av anteckningar i kundloggen
                    <br />
                  </label>
                </div>
              </div>
            </div>
            <br />

            {purgeLogsEnabled && (
              <>
                <Field
                  name={storage.customerLogMaxAge}
                  label="Radera anteckningar i kundloggen"
                  labelAfter="efter att anteckningen har lagts till eller ändrats"
                  description="Obs! Den här inställningen raderar anteckningar i kundloggen. Om du har t.ex. recept som lagts till för länge sedan så kan dessa raderas även om kunden varit bokad nyligen"
                  component={PeriodInput}
                /> <br />
              </>
            )}

            <Field
              name={storage.inactiveCustomerMaxAge}
              label="Radera kunduppgifter senast"
              labelAfter={purgeLogsEnabled ? 'efter att den sista bokningen eller anteckningen raderats' : 'efter att kundens sista bokning har raderats'}
              description={purgeLogsEnabled ? 'En kund raderas aldrig så länge det finns bokningar eller anteckningar i kundloggen' : 'En kund raderas aldrig om det finns en bokning kvar i systemet'}
              component={PeriodInput}
            />
            <br />
          </div>
        )}
        <hr />

        <div className="row">
          <div className="col-sm-offset-3 col-sm-6">
            <div className="alert alert-info">
              <strong>Vad händer nu?</strong>
              <br />
              Automatisk gallring av uppgifter kommer utföras varje natt enligt inställningarna ovan
              <br />
              <br />
              För att ni ska känna er trygga så kommer vi under en övergångsperiod lagra backuper av er data.
            </div>
          </div>
        </div>

        <div className="form-group">
          <div className="col-sm-offset-3 col-sm-5">
            <SubmitButton {...this.props} pristine={pristine && storageSettingsAccepted}>
              {!storageSettingsAccepted ? 'Godkänn inställningar' : 'Spara ändringar'}
            </SubmitButton>
            {isSysAdmin && (
              <ProgressButton small marginLeft danger onClick={restoreAutoDeleted}>Återställ raderade</ProgressButton>
            )}
          </div>
        </div>
      </form>
    );
  }
}

const validator = createValidator({
  bookingMaxAge: [required, timePeriod('P2W', 'P10Y')],
  customerLogMaxAge: [required, timePeriod('P2W', 'P10Y')],
  inactiveCustomerMaxAge: [required, timePeriod('P1D', 'P10Y')]
});

const selector = formValueSelector('storage-config');

const mapStateToProps = (state) => {
  return {
    storageSettingsAccepted: !!state.locationConfig.get(storage.settingsAccepted),
    autoPurgeEnabled: selector(state, storage.autoPurgeEnabled),
    purgeLogsEnabled: selector(state, storage.purgeLogsEnabled)
  };
};

export default reduxForm({
  form: 'storage-config',
  validate: validator,
  enableReinitialize: true
})(connect(mapStateToProps)(StorageConfigForm));
