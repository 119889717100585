import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { createValidator, maxValue, minValue, required } from '@Utils/validation';
import { getEmploymentTypeDescription } from '@Utils/users';
import TextInput from '@Components/inputs/text-input';
import FormButtonsFooter from '@Components/ui/form-buttons-footer';
import SelectField from '@Components/inputs/select-field';

const StaffEmploymentForm = ({
  pristine, submitting, submitSucceeded, handleSubmit, onClose
}) => {
  return (
    <form autoComplete="off" className="dialog" onSubmit={handleSubmit}>
      <Field
        name="employmentType"
        component={SelectField}
        label="Anställningsform"
      >
        <option value="">Välj anställningsform</option>
        {['FullTime', 'PartTime', 'SelfEmployed'].map(type => (
          <option value={type}>{getEmploymentTypeDescription(type)}</option>
        ))}
      </Field>
      <Field
        type="number"
        name="workHoursPerWeek"
        component={TextInput}
        label="Timmar per vecka"
      />
      <Field
        type="number"
        name="workDaysPerWeek"
        component={TextInput}
        label="Dagar per vecka"
      />
      <Field
        type="number"
        name="lunchMinutesPerDay"
        component={TextInput}
        label="Lunch (minuter/dag)"
      />
      <FormButtonsFooter
        onClose={onClose}
        pristine={pristine}
        submitting={submitting}
        submitSucceeded={submitSucceeded}
        handleSubmit={handleSubmit}
        submitText="Spara"
      />
    </form>
  );
};

const validator = createValidator({
  employmentType: [required],
  workHoursPerWeek: [required, minValue(0), maxValue(80)],
  workDaysPerWeek: [required, minValue(1), maxValue(7)],
  lunchMinutesPerDay: [required, minValue(0), maxValue(240)]
});

export default reduxForm({
  form: 'staff-employment-form',
  validate: validator
})(StaffEmploymentForm);
