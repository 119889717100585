import React from 'react';
import styled, { css } from 'styled-components';
import { colors, fs } from '@Components/ui/styled/variables';
import PulseLoader from '@Components/ui/pulse-loader';

const StyledButton = styled.button`
  position: relative;
  border-radius: 8px;
  text-align: center;
  vertical-align: middle;
  min-width: 170px;
  background-color: #fff;
  font-weight: 500;
  user-select: none;

  &:focus {
    box-shadow: 0 0 0 2px ${colors.grayBorderBoxShadowFocus};
  };
  .button-content {
    flex: 1;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-width: 0;
  }
  ${({ marginTop }) => marginTop && css`
    margin-top: 12px;
  `}
  ${({ marginRight }) => marginRight && css`
    margin-right: 12px;
  `}
  ${({ marginLeft }) => marginLeft && css`
    margin-left: 12px;
  `}
  ${({ marginBottom }) => marginBottom && css`
    margin-bottom: 12px;
  `}
  ${({ small }) => small && css`
    font-size: ${fs.s};
    width: fit-content;
    min-width: fit-content;
    border-radius: 5px;
    padding: 3px 12px;
    height: 30px;
    align-items: center;
    justify-content: center;
  `}
  ${({ tiny }) => tiny && css`
    font-size: ${fs.s};
    font-weight: 500;
    line-height: 16px;
    width: fit-content;
    min-width: fit-content;
    border-radius: 5px;
    padding: 3px 10px;
    height: 22px;
    align-items: center;
    justify-content: center;
  `}
  ${({ large }) => large && css`
    height: 45px;
    padding: 10px 24px;
    font-size: ${fs.l};
  `}
  ${({ block }) => block && css`
    width: 100%;
    min-width: 100%;
  `}

  ${({ primary, pink }) => (primary || pink) && css`
    background-color: ${colors.brandDarkPurple};
    color: #fff;
    border: none;
  `}
  ${({ outlinePrimary }) => outlinePrimary && css`
    border: solid 1px${colors.brandDarkPurple};
    color: ${colors.brandDarkPurple};
    background-color: transparent;
    &:active{
      background-color: ${colors.brandDarkPurple};
      color: #fff;
    }
  `}
  ${({ outlineDanger }) => outlineDanger && css`
    color: ${colors.danger};
    background-color: transparent;
    border: 1px solid ${colors.danger};
    &:active{
      background-color: ${colors.danger};
      border-color: #fff;
      color: #fff;
    }
  `}
  ${({ danger }) => danger && css`
  border: none;
    background-color: ${colors.defaultBtnBg};
    color: ${colors.danger};
    &:hover{
      background-color: ${colors.panelGrayBg};
    }
  `}
  ${({ confirm }) => confirm && css`
    border: solid 2px ${colors.greenCircle};
    color: #fff;
    background-color: ${colors.greenCircle};
    &:active{
      color: ${colors.greenCircle};
      background-color:#fff;
    }
  `}
  ${({ deny }) => deny && css`
    border: solid 2px ${colors.danger};
    color: #fff;
    background-color: ${colors.danger};
    &:active{
      color: ${colors.danger};
      background-color:#fff;
    }
  `}
  ${({ gray }) => gray && css`
    border: none;
    background-color: ${colors.defaultBtnBg};
    color: ${colors.defaultBtnColor};
    &:hover{
      background-color: ${colors.panelGrayBg};
    }
  `}
  ${({ white }) => white && css`
    border: none;
    background-color: #fff;
    color: ${colors.defaultBtnColor};
    &:hover{
      background-color: ${colors.defaultBtnBg};
    }
  `}
  ${({ yellow }) => yellow && css`
    color: #000;
    background-color: ${colors.bgYellowLight};
    border: none;
    border-radius: 4px;
    font-size: 13px;
    height: 30px;
    min-width: 100px;
  `};
  ${({ success }) => success && css`
    background-color: ${colors.bgSuccess};
    border: none;
    color: #fff;
  `};
  ${({ noBorder }) => noBorder && css`
    border: none;
    &:focus {
      border: none;
      box-shadow: none;
    }
  `};
  ${({ text }) => text && css`
    border: none;
    outline: none;
    background-color: transparent;
    text-decoration: underline;
    &:focus {
      box-shadow: none;
    }
  `};
  i.fa-chevron-down {
    padding-left: 5px;
    font-size: 11px;
  };
  ${({ linkStyle }) => linkStyle && css`
    min-width: fit-content;
    height: fit-content;
    border: none;
    font-size: 13px;
    color: ${colors.colorGrayLabel};
    cursor: pointer;
    width: fit-content;
    &:focus {
      box-shadow:none;
      border: none;
    }
  `};
  ${({ disabled }) => disabled && css`
    cursor: default;
    pointer-events: none;
    border: none;
    background-color: ${colors.softGray20};
    color: ${colors.darkGray40};
    &:hover {
      background-color: ${colors.softGray20};
      color: ${colors.darkGray40};
    }
  `}
  ${({ onClick }) => !onClick && css`
    cursor: default;
  `}
  ${({ showLoader }) => showLoader && css`
    .button-content {
      visibility: hidden;
    }
  `};
  ${({ minWidth }) => minWidth && css`
      min-width: ${minWidth};
  `};
  .button-loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .fa-lg-image {
    font-size: 20px;
    font-weight: 300;
    flex: 1;
    display: flex;
    align-items: center;
  }
  i.fa, i.far, i.fas {
    margin-top: -1px;
    vertical-align: middle;
  }
  a:hover, a:focus {
    color: inherit;
  }
  .label-news {
    right: -10px;
    top: -6px;
  }
`;

const Button = React.forwardRef(({
  children, loading, disabled, news, ...rest
}, ref) => {
  return (
    <StyledButton type="button" disabled={loading || disabled} showLoader={loading} ref={ref} {...rest}>
      {loading ? <PulseLoader className="button-loader" /> : null}
      {news ? <span className="label-news">NY</span> : null}
      <div className="button-content">{children}</div>
    </StyledButton>
  );
});

export default Button;
