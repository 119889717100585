import React from 'react';
import { reportingFormat, reportingCount } from '@Utils/format';
import { Row, Panel } from '@Components/ui/styled/main';
import { FlexOne, ReportTextLabel } from '@Components/pos/style';
import { getTotal } from '@Components/reports/reports-helpers';

const StockReportSummary = ({ products }) => {
  const totalQuantity = getTotal(products, 'stockQuantity');
  const totalPriceIn = products.reduce((sum, p) => {
    const total = parseFloat(p.priceIn || 0) * parseInt(p.stockQuantity || 0);
    return sum + total;
  }, 0);

  return (
    <Panel>
      <Row>
        <FlexOne>
          <ReportTextLabel>
            <span>Antal produkter</span>
            <p className="mb0">{reportingCount(products.length)}</p>
          </ReportTextLabel>
        </FlexOne>
        <FlexOne>
          <ReportTextLabel>
            <span>Totalt antal artiklar</span>
            <p className="mb0">{reportingCount(totalQuantity)}</p>
          </ReportTextLabel>
        </FlexOne>
        <FlexOne>
          <ReportTextLabel>
            <span>Totalt anskaffningsvärde</span>
            <p className="mb0">{reportingFormat(totalPriceIn)}</p>
          </ReportTextLabel>
        </FlexOne>
      </Row>
    </Panel>
  );
};

export default StockReportSummary;
