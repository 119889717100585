import React from 'react';
import { reduxForm } from 'redux-form';
import CurrencyUtil from '@Utils/currency-util';

import ModalDialog from '@Components/dialogs/modal-dialog';
import LabelSwitchInput from '@Components/ui/label-switch-input';
import CheckboxStyled from '@Components/inputs/checkbox-styled';
import { DialogTitleRow, DialogReceiptContentWrap, DialogTitleBtn, DialogTitle } from '@Components/dialogs/dialog-styles';
import { getItemDescription } from '@Utils/pos-utils';

const DialogPosRefundSelectItems = ({
  onNext, selectedItems, onSetSelectedItems, handleSelectAll, items, onClose, setReturnStock, returnStock
}) => {
  const isDisabled = Object.keys(selectedItems).length === 0
    || Object.values(selectedItems).some(i => !i.isValid);
  const hasProducts = items?.some(i => i.get('itemType') === 'Product');

  const handleToggleCheckBox = (id, amount = 0, qty = 0) => {
    if (Object.keys(selectedItems).includes(id)) {
      const newItems = {};
      Object.keys(selectedItems).filter(key => key !== id)
        .forEach(key => {
          newItems[key] = selectedItems[key];
        });
      onSetSelectedItems(newItems);
    } else {
      onSetSelectedItems({ ...selectedItems, [id]: { amount, qty, isValid: true } });
    }
  };

  const getItem = (key, qty) => {
    const item = items.find(i => i.get('itemId') === parseInt(key)).toJS();
    const { refundableAmount, refundableQty } = item;

    return {
      refundableAmount,
      refundableQty,
      maxAmount: (refundableAmount / refundableQty) * qty
    };
  };

  const handleSetQuantity = (key, qty) => {
    const { refundableAmount, refundableQty, maxAmount } = getItem(key, qty);
    const amount = qty === refundableQty ? refundableAmount : maxAmount;

    onSetSelectedItems({
      ...selectedItems,
      [key]: {
        ...selectedItems[key],
        qty,
        amount,
        isValid: amount > 0 && amount <= maxAmount
      }
    });
  };

  const handleSetAmount = (key, amount) => {
    const { maxAmount } = getItem(key, selectedItems[key].qty);

    onSetSelectedItems({
      ...selectedItems,
      [key]: {
        ...selectedItems[key],
        amount,
        isValid: amount > 0 && amount <= maxAmount
      }
    });
  };

  const handleToggleReturn = () => setReturnStock(!returnStock);

  const renderRow = (item) => {
    const {
      itemId, itemType, description, refundableAmount, refundableQty, initialQty
    } = item.toJS();

    const isDiscount = itemType === 'Discount';
    const isDisabled = refundableQty === 0;

    const key = String(itemId);
    const onClick = () => handleToggleCheckBox(key, refundableAmount, refundableQty);
    const onQtyChange = (ev) => handleSetQuantity(key, ev.target.value);
    const onAmountChange = (ev) => handleSetAmount(key, ev.target.value);
    const isChecked = Object.keys(selectedItems).includes(key);
    const { amount, qty, isValid } = selectedItems[key] || {};

    return !isDiscount ? (
      <tr key={itemId}>
        <td width="50%">
          <CheckboxStyled
            disabled={isDisabled}
            onClick={onClick}
            input={{ checked: isChecked }}
          >
            {getItemDescription(description, itemType)}
          </CheckboxStyled>
        </td>
        <td width="25%">
          {isChecked ? (
            <div className="select-container">
              <select className="form-control" value={qty || refundableQty} onChange={onQtyChange}>
                {Array.from({ length: initialQty }).map((d, index) => {
                  const qty = index + 1;
                  const disabled = refundableQty < qty;
                  return (
                    <option key={qty} value={qty} disabled={disabled}>{qty}</option>
                  );
                })}
              </select>
            </div>
          ) : `${qty || refundableQty} st`}
        </td>
        <td width="25%">
          {isChecked ? (
            <div className={isValid ? 'form-control-container' : 'form-control-container has-error'}>
              <label className="form-control-label">{CurrencyUtil.currencySymbol()}</label>
              <input
                type="number"
                className="form-control"
                onChange={onAmountChange}
                value={amount}
              />
            </div>
          ) : (
            CurrencyUtil.accountCurrency(refundableAmount, 2)
          )}
        </td>
      </tr>
    ) : null;
  };

  return (
    <ModalDialog
      contentSize="large"
      onClose={onClose}
      buttons={[{
        name: 'Nästa',
        primary: true,
        disabled: isDisabled,
        type: 'submit',
        onClick: onNext
      }]}
      closeOnClickOutside={false}
    >
      <DialogTitle>
        <DialogTitleBtn onClick={handleSelectAll}>Välj alla</DialogTitleBtn>
        <DialogTitleRow leftBtn center>Gör återköp</DialogTitleRow>
        <DialogTitleBtn onClick={onClose} tabIndex={1}>Avbryt</DialogTitleBtn>
      </DialogTitle>
      <DialogReceiptContentWrap>
        <table className="table table-refund">
          <tbody>
            {items?.map(renderRow)}
          </tbody>
        </table>
      </DialogReceiptContentWrap>
      {hasProducts && (
        <LabelSwitchInput
          noBorder
          label="Returnera artiklar till lager"
          id="returnStock"
          isOn={returnStock}
          handleToggle={handleToggleReturn}
        />
      )}
    </ModalDialog>
  );
};

export default reduxForm({
  form: 'dialog-refund'
})(DialogPosRefundSelectItems);
