import React from 'react';
import { reduxForm } from 'redux-form';
import { getItemDescription } from '@Utils/pos-utils';
import CurrencyUtil from '@Utils/currency-util';
import ModalDialog from '@Components/dialogs/modal-dialog';
import DialogAlert from '@Components/dialogs/dialog-alert';
import {
  DialogTitleBtn, BtnBack, DialogTitle, DialogTitleRow, DialogReceiptFullRow,
  DialogReceiptBold, DialogReceiptContentWrap, DialogReceiptFooterWrap, DialogReceiptLeftPos
} from '@Components/dialogs/dialog-styles';

function getRefundButtonText(paymentMethod, paymentMethodName, amount) {
  const amountText = CurrencyUtil.accountCurrency(amount);
  switch (paymentMethod) {
    case 'Card':
    case 'ExternalCardReader':
      return `Återbetala till kort: ${amountText}`;
    case 'Cash':
      return `Återbetala kontant: ${amountText}`;
    case 'Swish':
      return `Återbetala med Swish: ${amountText}`;
    case 'SwishMerchant':
      return `Återbetala med Swish Handel: ${amountText}`;
    case 'Klarna':
      return `Återbetala med Klarna: ${amountText}`;
    case 'KlarnaPayments':
      return `Återbetala med Klarna Payments: ${amountText}`;
    case 'Invoice':
    case 'FortnoxInvoice':
      return `Återbetala med faktura: ${amountText}`;
    case 'Voucher':
      return `Återbetala med presentkort: ${amountText}`;
    default:
      return paymentMethodName
        ? `Återbetala med ${paymentMethodName}: ${amountText}`
        : `Återbetala ${amountText}`;
  }
}

const DialogPosRefundConfirm = ({
  refundData, onPrev, onSubmit, onClose
}) => {
  const { refundSale, refundableAmount, options } = refundData || {};

  const optionButtons = options?.map(({ paymentMethod, paymentMethodName, maxRefundableAmount }, index) => {
    const amounts = [Math.abs(maxRefundableAmount), Math.abs(refundableAmount)];
    const amount = amounts.length > 0 ? Math.min(...amounts) : 0;

    return {
      name: getRefundButtonText(paymentMethod, paymentMethodName, amount),
      primary: index === 0,
      gray: index !== 0,
      type: 'submit',
      disabled: !amount,
      onClick: () => onSubmit(paymentMethod, paymentMethodName, amount)
    };
  });

  const renderRow = ({
    id, itemType, quantity, description, totalAmount
  }) => {
    return (
      <tr key={`${id}`}>
        <td>{getItemDescription(description, itemType)}</td>
        <td>{quantity} st</td>
        <td>{CurrencyUtil.accountCurrency(totalAmount, 2)}</td>
      </tr>
    );
  };

  if (!options || options.length === 0) {
    return (
      <DialogAlert
        warning
        icon="fa fa-exclamation-triangle"
        text="Det finns inga tillgängliga betalsätt för detta återköp"
        closeButtonText="Tillbaka"
        onClose={onPrev}
      />
    );
  }

  return (
    <ModalDialog
      contentSize="large"
      onClose={onClose}
      buttons={optionButtons}
      closeOnClickOutside={false}
    >
      <DialogTitle>
        <BtnBack onClick={onPrev}><i className="fas fa-chevron-left" /></BtnBack>
        <DialogTitleRow leftBtn center>Gör återköp</DialogTitleRow>
        <DialogTitleBtn onClick={onClose}>Avbryt</DialogTitleBtn>
      </DialogTitle>
      <DialogReceiptContentWrap>
        <table className="table table-refund">
          <tbody>
            {refundSale.items.map(data => renderRow(data))}
          </tbody>
        </table>
      </DialogReceiptContentWrap>
      <DialogReceiptFooterWrap>
        <DialogReceiptFullRow>
          <DialogReceiptBold>Att återbetala</DialogReceiptBold>
          <DialogReceiptLeftPos>
            {CurrencyUtil.accountCurrency(Math.abs(refundableAmount))}
          </DialogReceiptLeftPos>
        </DialogReceiptFullRow>
      </DialogReceiptFooterWrap>
    </ModalDialog>
  );
};

export default reduxForm({
  form: 'dialog-refund'
})(DialogPosRefundConfirm);
