import React, { useContext, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getSysadminStateSelectedOrganization } from '@Components/sysadmin/accounts/accounts-selector';
import AccountsContext from '@Components/sysadmin/accounts/accounts-context';
import NavTabsLink from '@Components/ui/nav-tabs-link';
import AccountsHeader from '@Components/sysadmin/accounts/account-detail/account-header/accounts-header';
import AccountOverview from '@Components/sysadmin/accounts/account-detail/account-overview/account-overview';
import AccountLocations from '@Components/sysadmin/accounts/account-detail/account-locations/account-locations';
import AccountInvoices from '@Components/sysadmin/accounts/account-detail/account-invoices/account-invoices';
import AccountPrefs from './account-prefs/account-prefs';
import AccountLogs from './account-logs';

const url = '/sysadmin/:org/:section?/:id?/';

const AccountsDetail = ({ loading }) => {
  const { routeParams } = useContext(AccountsContext);
  const selectedOrg = useSelector(state => getSysadminStateSelectedOrganization(state, routeParams.id));

  const sections = useMemo(() => {
    const logLabel = selectedOrg?.get('logCount')
      ? selectedOrg.get('logCount')
      : null;

    return [
      { navId: 'overview', name: 'Översikt' },
      { navId: 'locations', name: 'Konton' },
      { navId: 'invoices', name: 'Fakturor' },
      { navId: 'prefs', name: 'Inställningar' },
      { navId: 'logs', name: 'Logg', label: logLabel }
    ];
  }, [selectedOrg]);

  if (!selectedOrg) {
    return null;
  }

  return (
    <>
      <AccountsHeader loading={loading} />
      <NavTabsLink
        subUrl="accounts"
        sections={sections}
        className="modal-margins mt1"
        routeParams={routeParams}
      />
      <Switch>
        <Route path={`${url}overview`} component={AccountOverview} />
        <Route path={`${url}locations`} component={AccountLocations} />
        <Route path={`${url}invoices`} component={AccountInvoices} />
        <Route path={`${url}prefs`} component={AccountPrefs} />
        <Route path={`${url}logs`} component={AccountLogs} />
      </Switch>
    </>
  );
};

export default AccountsDetail;
